import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import './NotFound.scss'

type Props = {
  locale: string
}

const NotFound = ({ locale }: Props): ReactElement => {
  const { formatMessage } = useIntl()

  const textNotFound = formatMessage({
    id: 'NotFound',
    defaultMessage: 'Die Seite konnte nicht gefunden werden.',
  })

  const textBackHome = formatMessage({
    id: 'BackHome',
    defaultMessage: 'Zurück zur Startseite.',
  })

  return (
    <section className="NotFound">
      <div className="NotFound__inner">
        {textNotFound}
        <br />
        <Link to={`/${locale}`}>{textBackHome}</Link>
      </div>
    </section>
  )
}

export default NotFound
