const findFirstImage = (contents: any[]): string => {
  let imageUrl = ''

  contents.map((item: any) => {
    const { __typename: type, blockHidden } = item

    if (blockHidden || imageUrl !== '') {
      return null
    }

    if (type === 'contents_image_BlockType') {
      const blockImageOne = item.blockImage.length > 0 && item.blockImage[0]

      if (blockImageOne) {
        imageUrl = blockImageOne.url
        return null
      }
    } else if (type === 'contents_slideshow_BlockType') {
      const relatedSlideshow =
        item.blockSlideshow.length > 0 && item.blockSlideshow[0]

      if (relatedSlideshow) {
        const slideshowImageOne =
          relatedSlideshow.slideshowContents.length > 0 &&
          relatedSlideshow.slideshowContents[0]

        if (slideshowImageOne) {
          imageUrl = slideshowImageOne.url
        }
      }
    }

    return null
  })

  return imageUrl
}

export default findFirstImage
