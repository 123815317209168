import React, {
  ChangeEvent,
  KeyboardEvent,
  useRef,
  useState,
  useEffect,
  ReactElement,
} from 'react'
import { useIntl } from 'react-intl'
import { animateScroll } from 'react-scroll'
import { NavLink } from 'react-router-dom'
import { useNavigate, useMatch } from 'react-router-dom'
import classnames from 'classnames'

import { IS_SERVER_SIDE, BREAKPOINT_MOBILE } from 'Constants'
import useWindowSize from 'misc/useWindowSize'
import {
  FilterStateIndexType,
  CategoryPurposeType,
  CategoryOfferType,
} from 'data/types'
import useParamsQuery from 'misc/useParamsQuery'
import useDebounce from 'misc/useDebounce'
import LangSwitcher from 'view/components/lang-switcher/LangSwitcher'
import HeaderSmall from './HeaderSmall'
import HeaderFilterSection from './HeaderFilterSection'
import { HeaderProviderProps } from './HeaderProvider'

import './Header.scss'

interface Props extends HeaderProviderProps {
  global: {
    siteName: string
  }
  categoryPurpose: CategoryPurposeType[]
  categoryOffer: CategoryOfferType[]
}

const Header = ({
  categoryPurpose,
  categoryOffer,
  actionChangeFilters,
  actionCloseArticles,
  filters,
  global,
  locale,
  searchValue,
}: Props): ReactElement => {
  const { winWidth } = useWindowSize(true)
  const didMountRef = useRef(false)
  const params = useParamsQuery()
  const [searchTerm, setSearchTerm] = useState(params.get('s') || '')
  const debouncedSearchTerm = useDebounce(searchTerm, 800)
  const [searchInFocus, setSearchInFocus] = useState(false)
  const [searchInFocusMobile, setSearchInFocusMobile] = useState(false)
  const [searchPressedEnter, setSearchPressedEnter] = useState(false)
  const [categoryInFocus, setCategoryInFocus] = useState(false)
  const navigate = useNavigate()
  const isAgenda = useMatch('/:lang/agenda')
  const isPage = useMatch('/:lang/page/:slug')
  const { formatMessage } = useIntl()

  const handleFilterItemClick =
    (type: FilterStateIndexType, id: string) => () => {
      navigate(`/${locale}?type=${type}&tag=${id}`)
    }

  const handleSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    const find = ev.target.value
    setCategoryInFocus(false)
    setSearchTerm(find)
  }

  const handleSearchKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      window.setTimeout(() => setSearchInFocusMobile(false), 600)
      setSearchPressedEnter(true)

      if (window.document.activeElement) {
        window.setTimeout(
          () => (window as any).document.activeElement.blur(),
          400
        )
      }
    }
  }

  const handleSearchFocus = () => {
    setCategoryInFocus(false)
    setSearchInFocus(true)
    setSearchInFocusMobile(true)

    // go to top of page, so that input field is visible in iPhone
    if (winWidth <= BREAKPOINT_MOBILE) {
      window.setTimeout(() => {
        animateScroll.scrollToTop({
          duration: 400,
          smooth: 'easeOutQuad',
        })
      }, 500)
    }
  }

  const handleSearchBlur = () => {
    setSearchInFocus(false)
    setSearchPressedEnter(false)
  }

  const handleHomeClick = () => {
    actionCloseArticles()
    navigate(`/${locale}`)
  }

  const handleResetClick = () => {
    actionCloseArticles()

    if (isAgenda) {
      navigate(`/${locale}/agenda`)
    } else {
      navigate(`/${locale}`)
    }
  }

  // set params already, so that redux has to correct settings
  // in order to display the right content directly from the server
  if (IS_SERVER_SIDE) {
    const searchParam: string = params.get('s') || ''
    const typeParam = params.get('type') || null
    const tagParam = params.get('tag') || null

    if (
      typeParam &&
      ['purpose', 'offer'].includes(typeParam) &&
      tagParam &&
      !filters[typeParam as FilterStateIndexType].includes(tagParam)
    ) {
      actionChangeFilters(
        {
          purpose: [],
          offer: [],
          [typeParam]: [tagParam],
        },
        ''
      )
    } else if (searchParam !== searchValue) {
      actionChangeFilters(
        {
          purpose: [],
          offer: [],
        },
        searchParam
      )
    }
  }

  // only update search param in a debounced way,
  // otherwise too many queries to API
  useEffect(() => {
    if (didMountRef.current) {
      const empty = debouncedSearchTerm.length === 0

      // do not trigger again, if we are in category
      if (!categoryInFocus) {
        if (isAgenda) {
          if (empty) {
            navigate(`/${locale}/agenda`, { replace: true })
          } else {
            navigate(`/${locale}/agenda?s=${debouncedSearchTerm}`, {
              replace: true,
            })
          }
        } else {
          if (empty) {
            navigate(`/${locale}`, { replace: true })
          } else {
            navigate(`/${locale}?s=${debouncedSearchTerm}`, { replace: true })
          }
        }
      }
    } else {
      didMountRef.current = true
    }
  }, [debouncedSearchTerm])

  // set state to url query params (search or tag)
  useEffect(() => {
    const searchParam: string = params.get('s') || ''
    const typeParam = params.get('type') || null
    const tagParam = params.get('tag') || null

    if (
      typeParam &&
      ['purpose', 'offer'].includes(typeParam) &&
      tagParam &&
      !filters[typeParam as FilterStateIndexType].includes(tagParam)
    ) {
      actionChangeFilters(
        {
          purpose: [],
          offer: [],
          [typeParam]: [tagParam],
        },
        ''
      )

      setCategoryInFocus(true)
      setSearchTerm('')
    } else if (
      (typeParam === null || tagParam === null) &&
      (filters.purpose.length > 0 || filters.offer.length > 0)
    ) {
      actionChangeFilters(
        {
          purpose: [],
          offer: [],
        },
        ''
      )

      setCategoryInFocus(true)
      setSearchTerm('')
    } else if (searchParam !== searchValue) {
      actionChangeFilters(
        {
          purpose: [],
          offer: [],
        },
        searchParam
      )

      setSearchTerm(searchParam)
    }
  }, [actionChangeFilters, filters, params, searchValue])

  // when navigating to a page, search field is cleared, which would
  // trigger a new search and showing homepage again, we
  // fix that by detecting page navigations and ignoring search
  useEffect(() => {
    if (isPage) {
      setCategoryInFocus(true)
    }
  }, [isPage])

  const textSearch = formatMessage({
    id: 'Search',
    defaultMessage: 'Suche',
  })

  const navigation = (
    <div className="Header__navigation">
      <nav>
        <ul>
          <li className="Header__navigation__agenda">
            <NavLink
              to={`/${locale}/agenda`}
              className={({ isActive }) => (isActive ? 'h-state--active' : '')}
            >
              Agenda
            </NavLink>
          </li>
          <li>
            <input
              className="Header__search"
              onChange={handleSearch}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              onKeyDown={handleSearchKeydown}
              value={searchTerm}
              placeholder={
                searchInFocus ? '' : searchTerm === '' ? textSearch : ''
              }
            />
          </li>
        </ul>
      </nav>
    </div>
  )

  const headerContent = (
    <>
      <LangSwitcher />

      <button className="Header__logo" onClick={handleHomeClick}>
        {(global && global.siteName) || 'Kunstverein St.Gallen'}
      </button>

      <HeaderFilterSection
        type="purpose"
        items={categoryPurpose}
        selected={filters}
        handleItemClick={handleFilterItemClick}
      />

      <HeaderFilterSection
        type="offer"
        items={categoryOffer}
        selected={filters}
        handleItemClick={handleFilterItemClick}
        handleReset={handleResetClick}
      />

      {navigation}
    </>
  )

  const headerSmallContent = (
    <HeaderSmall
      global={global}
      locale={locale}
      searchInFocus={searchInFocus}
      setSearchInFocusMobile={setSearchInFocusMobile}
      searchPressedEnter={searchPressedEnter}
      actionCloseArticles={actionCloseArticles}
    >
      <HeaderFilterSection
        type="purpose"
        items={categoryPurpose}
        selected={filters}
        handleItemClick={handleFilterItemClick}
      />

      <HeaderFilterSection
        type="offer"
        items={categoryOffer}
        selected={filters}
        handleItemClick={handleFilterItemClick}
        handleReset={handleResetClick}
      />

      {navigation}
    </HeaderSmall>
  )

  const classes = classnames('Header', {
    'Header--searchfocus': searchInFocusMobile,
  })

  return (
    <header className={classes}>
      <div className="Header__normal">{headerContent}</div>

      <div className="Header__normal__fixed">{headerContent}</div>

      {headerSmallContent}
    </header>
  )
}

export default Header
