import { AnyAction } from 'redux'

import { ShareContentType } from 'data/types'
import { SHARE_OPEN_DIALOG, SHARE_CLOSE_DIALOG } from './types'

type ShareStateType = {
  open: boolean
  content: ShareContentType
}

const initialState: ShareStateType = {
  open: false,
  content: {
    headline: '',
    lead: '',
    image: '',
    url: '',
  },
}

export default function (
  state = initialState,
  action: AnyAction
): ShareStateType {
  switch (action.type) {
    case SHARE_OPEN_DIALOG: {
      return {
        ...state,
        open: true,
        content: action.payload,
      }
    }

    case SHARE_CLOSE_DIALOG: {
      return {
        ...state,
        open: false,
      }
    }

    default:
      return state
  }
}
