import React, { ReactElement } from 'react'
import striptags from 'striptags'

import { BASE_URL } from 'Constants'
import { ImageType } from 'data/types'
import Blocks from 'view/components/blocks/Blocks'
import Helmet from 'view/components/helmet/DefaultHelmet'

import './Page.scss'

type Props = {
  locale: string
  slug: string
  headline: string
  contents: any
}

const Page = ({ locale, slug, headline, contents }: Props): ReactElement => {
  const headlineClean = striptags(headline, ['br', 'em', 'i', 'sup', 'sub'])
  const blocks = <Blocks contents={contents} />

  // define SEO content
  let helmetDescription = ''
  const helmetImages: ImageType[] = []

  contents.map((item: any) => {
    const { __typename: type, blockHidden } = item

    if (blockHidden) {
      return null
    }

    if (type === 'contents_image_BlockType') {
      const image =
        item.blockImage && item.blockImage.length > 0 && item.blockImage[0]

      if (image) {
        helmetImages.push(image)
      }
    } else if (type === 'contents_text_BlockType' && helmetDescription === '') {
      helmetDescription = item.blockContent
    }

    return null
  })

  const helmetParams = {
    title: headline,
    description: helmetDescription,
    url: `${BASE_URL}/${locale}/page/${slug}`,
    images: helmetImages,
  }

  return (
    <>
      <Helmet {...helmetParams} />
      <section className="Page">
        <div className="Page__inner">
          <div className="Page__headline">
            <h1 dangerouslySetInnerHTML={{ __html: headlineClean }} />
          </div>

          {blocks}
        </div>
      </section>
    </>
  )
}

export default Page
