import { gql } from '@apollo/client'

const SLIDESHOW_QUERY = gql`
  query SlideshowQuery($slug: [String]) {
    slideshow: entry(section: ["slideshow"], slug: $slug, siteId: 1) {
      id
      title
      ... on slideshow_slideshow_Entry {
        slideshowContents {
          id
          url @transform(handle: "large")
          title
          width
          height
        }
      }
    }
  }
`

export default SLIDESHOW_QUERY
