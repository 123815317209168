/* eslint-disable */
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'

import { IS_DEV } from 'Constants'
import { RootState } from 'data/redux/rootReducer'

const mapStateToProps = (state: RootState) => ({
  googleAnalyticsId: state.meta.googleAnalyticsId,
})

const connector = connect(mapStateToProps)

type AnalyticsProps = ConnectedProps<typeof connector>

export const trackPage = (path: string) => {
  if (!IS_DEV) {
    ReactGA.send({ hitType: 'pageview', page: path })
  }
}

const Analytics = ({ googleAnalyticsId }: AnalyticsProps): null => {
  const { pathname } = useLocation()

  if (googleAnalyticsId && !IS_DEV) {
    ReactGA.initialize(googleAnalyticsId)
  }

  useEffect(() => {
    if (googleAnalyticsId && !IS_DEV) {
      ReactGA.send({ hitType: 'pageview', page: pathname })
    }
  }, [googleAnalyticsId, pathname, IS_DEV])

  return null
}

export default connector(Analytics)
