import { Action, ActionCreator } from 'redux'
import { ShareContentType } from 'data/types'
import { SHARE_OPEN_DIALOG, SHARE_CLOSE_DIALOG } from './types'

export const shareOpenDialog: ActionCreator<Action> = (
  data: ShareContentType
) => ({
  type: SHARE_OPEN_DIALOG,
  payload: data,
})

export const shareCloseDialog: ActionCreator<Action> = () => ({
  type: SHARE_CLOSE_DIALOG,
})
