import React from 'react'

import { ImageType } from 'data/types'
import BlockSlideshow from 'view/components/blocks/BlockSlideshow'

type Props = {
  title: string
  slideshowContents: ImageType[]
}

const Slideshow = ({ title, slideshowContents }: Props) => {
  const blockSlideshow = [
    {
      slideshowContents,
    },
  ]

  return (
    <section className="Detail">
      <div className="Detail__inner">
        <article className="Article">
          <div className="Article__inner">
            <BlockSlideshow
              blockStyle="large"
              blockSlideshow={blockSlideshow}
              blockCaption={title}
            />
          </div>
        </article>
      </div>
    </section>
  )
}

export default Slideshow
