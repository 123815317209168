import { gql } from '@apollo/client'

export const HOME_CATEGORY_QUERY = gql`
  query HomeCategoryQuery($id: [QueryArgument], $siteId: [QueryArgument]) {
    category(id: $id, siteId: $siteId) {
      id
      siteId
      title
      ... on categoryOffer_Category {
        categoryDescription
      }
      ... on categoryPurpose_Category {
        categoryDescription
      }
    }
  }
`

export const HOME_SEARCH_QUERY = gql`
  query HomeSearchQuery(
    $search: String
    $categoryPurpose: [QueryArgument]
    $categoryOffer: [QueryArgument]
    $siteId: [QueryArgument]
  ) {
    results: entries(
      section: ["articles"]
      search: $search
      categoryPurpose: $categoryPurpose
      categoryOffer: $categoryOffer
      siteId: $siteId
    ) {
      id
      siteId
      slug
      postDate
      __typename
      ... on articles_article_Entry {
        previewTextColor
        headline
        lead
        kind
        previewImage {
          id
          url @transform(handle: "large")
          title
          width
          height
        }
        previewVideo {
          id
          url
        }
        previewVideoWidth
        previewVideoHeight
        contents {
          __typename
          ... on contents_subtitle_BlockType {
            id
            blockHidden
            blockContent
          }
          ... on contents_text_BlockType {
            id
            blockHidden
            blockNoPaddingBottom
            blockIndent
            blockContent
          }
          ... on contents_image_BlockType {
            id
            blockHidden
            blockStyle
            blockImage {
              id
              url @transform(handle: "large")
              title
              width
              height
            }
            blockCaption
          }
          ... on contents_video_BlockType {
            id
            blockHidden
            blockStyle
            blockVideo {
              id
              url
            }
            blockVideoWidth
            blockVideoHeight
            blockCaption
          }
          ... on contents_slideshow_BlockType {
            id
            blockHidden
            blockStyle
            blockSlideshow {
              id
              ... on slideshow_slideshow_Entry {
                slideshowContents {
                  id
                  url @transform(handle: "large")
                  title
                  width
                  height
                }
              }
            }
            blockCaption
          }
        }
      }
      ... on articles_articleSplit_Entry {
        previewTextColor
        headline
        lead
        kind
        previewImage {
          id
          url @transform(handle: "large")
          title
          width
          height
        }
        previewVideo {
          id
          url
        }
        previewVideoWidth
        previewVideoHeight
        contents {
          __typename
          ... on contents_subtitle_BlockType {
            id
            blockHidden
            blockContent
          }
          ... on contents_text_BlockType {
            id
            blockHidden
            blockNoPaddingBottom
            blockIndent
            blockContent
          }
          ... on contents_image_BlockType {
            id
            blockHidden
            blockStyle
            blockImage {
              id
              url @transform(handle: "large")
              title
              width
              height
            }
            blockCaption
          }
          ... on contents_video_BlockType {
            id
            blockHidden
            blockStyle
            blockVideo {
              id
              url
            }
            blockVideoWidth
            blockVideoHeight
            blockCaption
          }
          ... on contents_slideshow_BlockType {
            id
            blockHidden
            blockStyle
            blockSlideshow {
              id
              ... on slideshow_slideshow_Entry {
                slideshowContents {
                  id
                  url @transform(handle: "large")
                  title
                  width
                  height
                }
              }
            }
            blockCaption
          }
        }
      }
      ... on articles_callToAction_Entry {
        callToActionText
        callToActionUrl
        callToActionBlank
      }
      ... on articles_testimonial_Entry {
        testimonialQuote
        testimonialAuthor
        testimonialImage {
          id
          url @transform(handle: "small")
          title
          width
          height
        }
      }
      ... on articles_agenda_Entry {
        headline
        subtitle
        lead
        agendaDate @formatDateTime(format: "d.m.Y", timezone: "Europe/Zurich")
        agendaTimeStart
          @formatDateTime(format: "H.i", timezone: "Europe/Zurich")
        agendaTimeEnd @formatDateTime(format: "H.i", timezone: "Europe/Zurich")
        agendaLocation
      }
    }
  }
`
