import { motion } from 'framer-motion'
import React, { ReactElement, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationExternalLinkType, NavigationLinkType } from 'data/types'

import './Footer.scss'

type FooterProps = {
  locale: string
  navigation: (NavigationExternalLinkType | NavigationLinkType)[]
}

const Footer = ({ locale, navigation }: FooterProps): ReactElement => {
  const [open, setOpen] = useState(false)
  const itemsLeft: any = []
  const itemsRight: any = []

  const handleToggleOpen = () => setOpen(!open)
  const handleUnderlayClose = () => setOpen(false)
  const handleLinkClick = () => setOpen(false)
  const handleExternalLinkClick = (url: string) => () => {
    window.open(url)
    setOpen(false)
  }

  // every time a new page gets visited,
  // we close the small navigation
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if ((event.key === 'Esc' || event.key === 'Escape') && open) {
        setOpen(false)
      }
    }

    window.addEventListener('keydown', keyDownHandler)

    return () => window.removeEventListener('keydown', keyDownHandler)
  }, [open, setOpen])

  navigation.forEach((item: any) => {
    const { id, __typename, title, navigationMenu } = item
    let html = null

    if (
      __typename === 'navigation_navigation_Entry' &&
      item.navigationRelatedPage &&
      item.navigationRelatedPage.length > 0
    ) {
      html = (
        <li key={id}>
          <NavLink
            to={`/${locale}/page/${item.navigationRelatedPage[0].slug}`}
            className={({ isActive }) => (isActive ? 'h-state--active' : '')}
            onClick={handleLinkClick}
          >
            {title}
          </NavLink>
        </li>
      )
    } else if (__typename === 'navigation_externalLink_Entry') {
      html = (
        <li key={id}>
          <button
            className="Footer__link__button"
            onClick={handleExternalLinkClick(item.navigationExternalUrl)}
          >
            {title}
          </button>
        </li>
      )
    }

    if (navigationMenu === 'left') {
      itemsLeft.push(html)
    } else if (navigationMenu === 'right') {
      itemsRight.push(html)
    }
  })

  const menuVariants = {
    open: {
      y: '-100%',
    },
    closed: {
      y: '0',
    },
  }

  const footerContent = (
    <div className="Footer__normal">
      <div className="Footer__left">
        {itemsLeft.length > 0 && (
          <nav className="Footer__nav">
            <ul>{itemsLeft}</ul>
          </nav>
        )}
      </div>
      <div className="Footer__right">
        {itemsRight.length > 0 && (
          <nav className="Footer__nav">
            <ul>{itemsRight}</ul>
          </nav>
        )}
      </div>
    </div>
  )

  const footerSmallContent = (
    <div className="Footer__small">
      <div className="Footer__small__closed">
        <button
          className="Footer__small__closed__button"
          onClick={handleToggleOpen}
        >
          <span className="Footer__small__closed__button__left">Info</span>
          <span className="Footer__small__closed__button__right">
            <svg viewBox="0 0 60 60">
              <line x1="1.7" y1="30" x2="57.8" y2="30" />
              <line x1="29.7" y1="2" x2="29.7" y2="58" />
            </svg>
          </span>
        </button>
      </div>

      <motion.div
        className="Footer__small__open"
        initial="closed"
        animate={open ? 'open' : 'closed'}
        variants={menuVariants}
        transition={{ type: 'spring', damping: 22, restDelta: 0.5 }}
      >
        <button
          className="Footer__small__open__button"
          onClick={handleToggleOpen}
        >
          <svg viewBox="0 0 60 60">
            <line x1="9.9" y1="10.2" x2="49.6" y2="49.8" />
            <line x1="49.6" y1="10.2" x2="9.9" y2="49.8" />
          </svg>
        </button>
        <nav className="Footer__nav">
          <ul>
            {itemsLeft}
            {itemsRight}
          </ul>
        </nav>
      </motion.div>
    </div>
  )

  return (
    <section className="Footer">
      <div className="Footer__inner">
        {footerContent}
        {footerSmallContent}
      </div>

      {open && (
        <button
          className="Footer__small__underlay"
          onClick={handleUnderlayClose}
        />
      )}
    </section>
  )
}

export default Footer
