import React, { ReactElement, useState } from 'react'
import Ticker from 'react-ticker'
import striptags from 'striptags'

import './CallToAction.scss'

type Props = {
  callToActionText: string
  callToActionUrl: string
  callToActionBlank: boolean
}

const CallToAction = ({
  callToActionText,
  callToActionUrl,
  callToActionBlank,
}: Props): ReactElement => {
  const [move, setMove] = useState(true)
  const mouseEnter = () => {
    setMove(false)
  }
  const mouseLeave = () => {
    setMove(true)
  }

  const actionText = striptags(callToActionText, [
    'br',
    'em',
    'i',
    'sup',
    'sub',
  ])
  const tickerOptions = {
    speed: 10,
    move,
  }
  const ticker = (
    <Ticker {...tickerOptions}>
      {() => (
        <>
          <span>{actionText}</span>
          <span>&nbsp;+ + +&nbsp;</span>
        </>
      )}
    </Ticker>
  )

  let contents = <div className="CallToAction__inner">{ticker}</div>

  if (callToActionUrl) {
    if (callToActionBlank) {
      contents = (
        <a
          href={callToActionUrl}
          className="CallToAction__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="CallToAction__inner">{ticker}</div>
        </a>
      )
    } else {
      contents = (
        <a href={callToActionUrl} className="CallToAction__link">
          <div className="CallToAction__inner">{ticker}</div>
        </a>
      )
    }
  }

  return (
    <div
      className="CallToAction"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {contents}
    </div>
  )
}

export default CallToAction
