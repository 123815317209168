/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  MouseEvent,
  TouchEvent,
  ReactElement,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import classnames from 'classnames'
import striptags from 'striptags'

import { VideoType } from 'data/types'

import './BlockVideo.scss'

type Props = {
  blockStyle: string
  blockVideo: VideoType[]
  blockVideoWidth: number
  blockVideoHeight: number
  blockCaption: string
}

const BlockVideo = ({
  blockStyle,
  blockVideo,
  blockVideoWidth,
  blockVideoHeight,
  blockCaption,
}: Props): ReactElement | null => {
  const { formatMessage } = useIntl()
  const videoRef = useRef<HTMLVideoElement>(null)
  const seekRef = useRef<HTMLInputElement>(null)
  const seekTooltipRef = useRef<HTMLDivElement>(null)
  const [firstRun, setFirstRun] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [progressValue, setProgressValue] = useState(0)
  const [progressMaxValue, setProgressMaxValue] = useState(0)
  const [muted, setMuted] = useState(false)
  const [tooltipTouchActive, setTooltipTouchActive] = useState(false)
  const [controlsActive, setControlsActive] = useState(true)
  const blockVideoOne = blockVideo.length > 0 && blockVideo[0]

  const textPlay = formatMessage({
    id: 'VideoPlay',
    defaultMessage: 'Wiedergabe',
  })

  const textPause = formatMessage({
    id: 'VideoPause',
    defaultMessage: 'Pause',
  })

  const textMute = formatMessage({
    id: 'VideoMute',
    defaultMessage: 'Stummschalten',
  })

  const textUnmute = formatMessage({
    id: 'VideoUnmute',
    defaultMessage: 'Stummschalten aufheben',
  })

  const textFullscreen = formatMessage({
    id: 'VideoFullscreen',
    defaultMessage: 'Vollbild',
  })

  const mouseEnterVideo = () => setControlsActive(true)
  const mouseLeaveVideo = () => setControlsActive(false)

  const formatTime = (timeInSeconds: number) => {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)

    return {
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    }
  }

  const progressFunc = () => {
    if (videoRef && videoRef.current) {
      setProgressValue(Math.floor(videoRef.current.currentTime))
    }
  }

  const initFunc = () => {
    if (videoRef && videoRef.current) {
      if (videoRef.current.readyState >= 1) {
        const videoDuration = Math.round(videoRef.current.duration)
        setProgressMaxValue(videoDuration)
      }
    }
  }

  const playVideo = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.play()
      setFirstRun(false)
      setPlaying(true)
    }
  }

  const togglePlay = () => {
    if (videoRef && videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) {
        setPlaying(true)
        videoRef.current.play()
      } else {
        setPlaying(false)
        videoRef.current.pause()
      }
    }
  }

  const toggleSound = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted
      setMuted(videoRef.current.muted)
    }
  }

  const toggleFullScreen = () => {
    // cross broser fullscreen API
    // see https://gist.github.com/simonewebdesign/6183356
    if (videoRef && videoRef.current) {
      if (
        !window.document.fullscreenElement &&
        !window.document.mozFullScreenElement &&
        !window.document.webkitFullscreenElement &&
        !window.document.msFullscreenElement
      ) {
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen()
        } else if (videoRef.current.msRequestFullscreen) {
          videoRef.current.msRequestFullscreen()
        } else if (videoRef.current.mozRequestFullScreen) {
          videoRef.current.mozRequestFullScreen()
        } else if (videoRef.current.webkitRequestFullscreen) {
          videoRef.current.webkitRequestFullscreen(
            (window as any).Element.ALLOW_KEYBOARD_INPUT
          )
        } else if (videoRef.current.webkitSetPresentationMode) {
          videoRef.current.webkitSetPresentationMode('fullscreen')
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (window.document.exitFullscreen) {
          window.document.exitFullscreen()
        } else if (window.document.msExitFullscreen) {
          window.document.msExitFullscreen()
        } else if (window.document.mozCancelFullScreen) {
          window.document.mozCancelFullScreen()
        } else if (window.document.webkitExitFullscreen) {
          window.document.webkitExitFullscreen()
        } else if (videoRef.current.webkitSetPresentationMode) {
          videoRef.current.webkitSetPresentationMode('inline')
        }
      }
    }
  }

  const skipAhead = () => {
    if (!(window as any).Modernizr.touchevents) {
      const videoRefCurrent = videoRef && videoRef.current
      const seekRefCurrent = seekRef && seekRef.current

      if (videoRefCurrent && seekRefCurrent) {
        const skipTo = seekRefCurrent.dataset.seek

        if (skipTo) {
          videoRefCurrent.currentTime = window.parseInt(skipTo)
          setProgressValue(window.parseInt(skipTo) || 0)
        }
      }
    }
  }

  const updateSeekTooltip = (event: MouseEvent) => {
    const videoRefCurrent = videoRef && videoRef.current
    const seekRefCurrent = seekRef && seekRef.current
    const seekTooltipRefCurrent = seekTooltipRef && seekTooltipRef.current

    if (videoRefCurrent && seekRefCurrent && seekTooltipRefCurrent) {
      const skipTo = Math.round(
        (event.nativeEvent.offsetX / seekRefCurrent.clientWidth) *
          window.parseInt(seekRefCurrent.max, 10)
      )

      if (skipTo >= 0) {
        const t = formatTime(skipTo)

        seekRefCurrent.dataset.seek = skipTo.toString()
        seekTooltipRefCurrent.textContent = `${t.minutes}:${t.seconds}`
        seekTooltipRefCurrent.style.left = `${event.nativeEvent.offsetX}px`
      }
    }
  }

  const inputTouchStart = (event: TouchEvent) => {
    const videoRefCurrent = videoRef && videoRef.current
    const seekRefCurrent = seekRef && seekRef.current
    const seekTooltipRefCurrent = seekTooltipRef && seekTooltipRef.current

    if (videoRefCurrent && seekRefCurrent && seekTooltipRefCurrent) {
      let pageX = -1
      const rect = seekRefCurrent.getBoundingClientRect()

      if (event.changedTouches && event.changedTouches[0]) {
        pageX = event.changedTouches[0].pageX
      }

      const skipTo = Math.round(
        ((pageX - rect.x) / seekRefCurrent.clientWidth) *
          window.parseInt(seekRefCurrent.max, 10)
      )

      if (skipTo >= 0 && pageX > 0) {
        const t = formatTime(skipTo)

        seekRefCurrent.dataset.seek = skipTo.toString()
        seekTooltipRefCurrent.textContent = `${t.minutes}:${t.seconds}`
        seekTooltipRefCurrent.style.left = `${pageX - rect.x}px`

        videoRefCurrent.currentTime = skipTo
        setProgressValue(skipTo)
      }
    }

    setTooltipTouchActive(true)
  }

  const inputTouchEnd = () => setTooltipTouchActive(false)

  if (!blockVideoOne) {
    return null
  }

  // caption
  const captionClean = striptags(blockCaption, [
    'p',
    'br',
    'em',
    'i',
    'a',
    'ul',
    'li',
    'sup',
    'sub',
  ])
  const caption = captionClean && (
    <div
      className="BlockVideo__caption"
      dangerouslySetInnerHTML={{ __html: captionClean }}
    />
  )

  const ratio = blockVideoHeight / (blockVideoWidth > 0 ? blockVideoWidth : 1)
  const progressBarSpanStyle = {
    width: (progressValue / progressMaxValue) * 100 + '%',
  }

  return (
    <div
      className={classnames('BlockVideo', `BlockVideo--size-${blockStyle}`, {
        'BlockVideo--portrait': ratio > 1,
      })}
      onMouseEnter={mouseEnterVideo}
      onMouseLeave={mouseLeaveVideo}
    >
      <div className="BlockVideo__inner">
        <div
          className="BlockVideo__ratio"
          style={{ paddingTop: `${100 * ratio}%` }}
        />
        <video
          className="BlockVideo__video"
          ref={videoRef}
          playsInline
          loop
          preload="metadata"
          onLoadedMetadata={initFunc}
          onTimeUpdate={progressFunc}
        >
          <source src={`${blockVideoOne.url}#t=0.1`} type="video/mp4" />
        </video>

        {firstRun && (
          <button className="BlockVideo__btn__play" onClick={playVideo}>
            <svg viewBox="0 0 60 60">
              <path d="M29.7,8.5C17.9,8.5,8.2,18.1,8.2,30s9.6,21.5,21.5,21.5c11.9,0,21.5-9.6,21.5-21.5S41.6,8.5,29.7,8.5z M24.2,38V22l16,8L24.2,38z" />
            </svg>
          </button>
        )}

        {!firstRun && (
          <button
            className="BlockVideo__btn__play__overlay"
            onClick={togglePlay}
          />
        )}

        {!firstRun && (
          <div
            className={classnames('BlockVideo__controls', {
              'BlockVideo__controls--active': controlsActive,
            })}
          >
            <div className="BlockVideo__controls__left">
              <button
                className="BlockVideo__controls__pause__button"
                onClick={togglePlay}
                title={playing ? textPause : textPlay}
              >
                {playing && (
                  <svg viewBox="0 0 96.32 93.18">
                    <rect x="32.28" y="13.25" width="5" height="68.38" />
                    <rect x="59.04" y="13.25" width="5" height="68.38" />
                  </svg>
                )}
                {!playing && (
                  <svg viewBox="0 0 96.32 93.18">
                    <path d="M27.72,21.84l42.63,25.6l-42.63,25.6V21.84 M22.72,13.01v68.86l57.34-34.43L22.72,13.01L22.72,13.01z" />
                  </svg>
                )}
              </button>
              <button
                className="BlockVideo__controls__volume__button"
                onClick={toggleSound}
                title={muted ? textUnmute : textMute}
              >
                {muted && (
                  <svg viewBox="0 0 109.36 96.17">
                    <path
                      d="M5.15,33.91v28.62h13.81l27.76,19.75V13.89L18.96,33.91H5.15z M21.88,37.97l19.84-14.31v48.92L21.86,58.45l-1.3-0.93h-1.6
                      h-8.81V38.91h8.81h1.61L21.88,37.97z"
                    />
                    <polygon
                      points="88.34,35.68 84.8,32.14 72.91,44.04 61.01,32.14 57.47,35.68 69.37,47.58 57.47,59.47 61.01,63.01 72.91,51.11
                      84.8,63.01 88.34,59.47 76.44,47.58"
                    />
                  </svg>
                )}
                {!muted && (
                  <svg viewBox="0 0 109.36 96.17">
                    <path
                      d="M5.15,33.91v28.62h13.81l27.76,19.75V13.89L18.96,33.91H5.15z M21.88,37.97l19.84-14.31v48.92L21.86,58.45l-1.3-0.93h-1.6
                      h-8.81V38.91h8.81h1.61L21.88,37.97z"
                    />
                    <path
                      d="M85.06,1.98l-3.37,3.69c11.89,10.86,18.71,26.32,18.71,42.41c0,16.09-6.82,31.55-18.71,42.41l3.37,3.69
                      c12.92-11.81,20.33-28.62,20.33-46.11C105.39,30.59,97.98,13.79,85.06,1.98z"
                    />
                    <path
                      d="M72.88,15.72l-3.37,3.69c8.03,7.34,12.64,17.79,12.64,28.66c0,10.88-4.61,21.33-12.65,28.66l3.37,3.69
                      c9.07-8.28,14.27-20.08,14.27-32.36C87.14,35.8,81.94,24.01,72.88,15.72z"
                    />
                    <path
                      d="M61.15,28.94l-3.37,3.69c4.33,3.95,6.82,9.58,6.82,15.44c0,5.86-2.48,11.49-6.82,15.44l3.37,3.69
                      c5.37-4.9,8.44-11.87,8.44-19.14C69.59,40.82,66.51,33.84,61.15,28.94z"
                    />
                  </svg>
                )}
              </button>
            </div>

            <div className="BlockVideo__controls__center">
              <div className="BlockVideo__controls__progress">
                <div className="BlockVideo__controls__progress__bar">
                  <span style={progressBarSpanStyle} />
                </div>
                <input
                  ref={seekRef}
                  className="BlockVideo__controls__progress__seek"
                  value={progressValue}
                  min="0"
                  max={progressMaxValue}
                  type="range"
                  step="1"
                  onMouseMove={updateSeekTooltip}
                  onInput={skipAhead}
                  onTouchStart={inputTouchStart}
                  onTouchEnd={inputTouchEnd}
                  readOnly
                />
                <div
                  className={classnames(
                    'BlockVideo__controls__progress__seek__tooltip',
                    {
                      'BlockVideo__controls__progress__seek__tooltip--active':
                        tooltipTouchActive,
                    }
                  )}
                  ref={seekTooltipRef}
                >
                  00:00
                </div>
              </div>
            </div>

            <div className="BlockVideo__controls__right">
              <button
                className="BlockVideo__controls__fullscreen__button"
                onClick={toggleFullScreen}
                title={textFullscreen}
              >
                <svg viewBox="0 0 96.32 93.18">
                  <polygon points="18.97,35.58 18.97,18.25 36.49,18.25 36.49,13.25 13.97,13.25 13.97,35.58" />
                  <polygon points="60.2,18.25 77.35,18.25 77.35,35.58 82.35,35.58 82.35,13.25 60.2,13.25" />
                  <polygon points="77.35,59.29 77.35,76.63 60.2,76.63 60.2,81.63 82.35,81.63 82.35,59.29" />
                  <polygon points="36.49,76.63 18.97,76.63 18.97,59.29 13.97,59.29 13.97,81.63 36.49,81.63" />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
      {caption}
    </div>
  )
}

export default BlockVideo
