import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'

import { mapLangToSiteId } from 'misc/i18n'
import PERSON_GALLERY_QUERY from 'data/queries/person-gallery'
import { RootState } from 'data/redux/rootReducer'
import PersonGallery from './PersonGallery'
import Loading from 'view/components/loading/Loading'
import NotFound from 'view/content/not-found/NotFoundProvider'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
})

const connector = connect(mapStateToProps)

export type PersonGalleryProviderProps = ConnectedProps<typeof connector>

const PersonGalleryProvider = ({
  locale,
}: PersonGalleryProviderProps): ReactElement | null => {
  const { slug } = useParams()

  const { data, loading } = useQuery(PERSON_GALLERY_QUERY, {
    variables: {
      slug,
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (loading) {
    return <Loading />
  }

  if (data && data.gallery) {
    return <PersonGallery {...data.gallery} />
  }

  return <NotFound />
}

export default connector(PersonGalleryProvider)
