import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'

import { mapLangToSiteId } from 'misc/i18n'
import { RootState } from 'data/redux/rootReducer'
import PAGE_QUERY from 'data/queries/page'
import Page from './Page'
import Loading from 'view/components/loading/Loading'
import NotFound from 'view/content/not-found/NotFoundProvider'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const PageProvider = ({ locale }: PropsFromRedux) => {
  const { slug } = useParams()

  const { data, loading } = useQuery(PAGE_QUERY, {
    variables: {
      slug,
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (loading) {
    return <Loading />
  }

  if (data && data.page) {
    return <Page locale={locale} slug={slug} {...data.page} />
  }

  return <NotFound />
}

export default connector(PageProvider)
