import React, { useState, useRef, ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { animateScroll } from 'react-scroll'
import { connect, ConnectedProps } from 'react-redux'
import fetch from 'isomorphic-unfetch'
import classnames from 'classnames'

import { BACKEND_URL } from 'Constants'
import {
  MembershipFormInputsType,
  MembershipFormSentMessageType,
} from 'data/types'
import { RootState } from 'data/redux/rootReducer'

import './BlockMembershipForm.scss'

const mapStateToProps = (state: RootState) => ({
  successMsg: state.meta.membershipFormSuccessMsg,
  locale: state.global.locale,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const BlockMembershipForm = ({
  successMsg,
  locale,
}: PropsFromRedux): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const [focusFields, setFocusFields] = useState({
    lastName: false,
    firstName: false,
    partner: false,
    dateOfBirth: false,
    street: false,
    zip: false,
    email: false,
    phone: false,
    notes: false,
  })
  const [formSentMessage, setFormSentMessage] =
    useState<MembershipFormSentMessageType>({})
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MembershipFormInputsType>()
  const { formatMessage } = useIntl()

  const focusHandler = (field: string) => () => {
    setFocusFields({
      ...focusFields,
      [field]: true,
    })
  }

  const blurHandler = (field: string) => () => {
    setFocusFields({
      ...focusFields,
      [field]: false,
    })
  }

  const onSubmit = (dataFromForm: MembershipFormInputsType) => {
    if (BACKEND_URL) {
      // convert to form-data, because that is what Craft needs
      const formData = new FormData()
      Object.keys(dataFromForm).forEach((key) =>
        formData.append(key, dataFromForm[key])
      )

      // add action query, so Craft knows where to send request
      formData.append('action', 'kunstverein/frontend/submit')

      // indicate that we are processing the form on the backend
      setFormSentMessage({ processing: true })

      // do fetch POST request
      fetch(BACKEND_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setFormSentMessage(data)

          // scroll to top, so success message is visible
          if (data.success && ref && ref.current) {
            const clientRect = ref.current.getBoundingClientRect()
            animateScroll.scrollTo(clientRect.top + window.scrollY - 250, {
              duration: 400,
              smooth: 'easeOutQuad',
            })
          }
        })
        .catch((error) => console.error(error))
    }
  }

  // i18n messages
  const textFilloutFields = formatMessage({
    id: 'FormFilloutFields',
    defaultMessage: 'Bitte füllen Sie alle mit einem * markierten Felder aus.',
  })

  const textType = formatMessage({
    id: 'FormType',
    defaultMessage: 'Art der Mitgliedschaft*',
  })

  const textTypeSingle = formatMessage({
    id: 'FormTypeSingle',
    defaultMessage: 'Einzelperson',
  })

  const textTypeSinglePrice = formatMessage({
    id: 'FormTypeSinglePrice',
    defaultMessage: 'CHF 80.– pro Jahr',
  })

  const textTypeCouple = formatMessage({
    id: 'FormTypeCouple',
    defaultMessage: 'Paar',
  })

  const textTypeCouplePrice = formatMessage({
    id: 'FormTypeCouplePrice',
    defaultMessage: 'CHF 120.– pro Jahr',
  })

  const textTypeFamily = formatMessage({
    id: 'FormTypeFamily',
    defaultMessage: 'Familie ',
  })

  const textTypeFamilyPrice = formatMessage({
    id: 'FormTypeFamilyPrice',
    defaultMessage: 'CHF 120.– pro Jahr',
  })

  const textTypeJunior = formatMessage({
    id: 'FormTypeJunior',
    defaultMessage: 'Junior (bis zum 26. Lebensjahr)',
  })

  const textTypeJuniorPrice = formatMessage({
    id: 'FormTypeJuniorPrice',
    defaultMessage: 'CHF 35.– pro Jahr',
  })

  const textTypeArtist = formatMessage({
    id: 'FormTypeArtist',
    defaultMessage: 'Künstlerin oder Künstler mit visarte-Mitgliedschaft',
  })

  const textTypeArtistPrice = formatMessage({
    id: 'FormTypeArtistPrice',
    defaultMessage: 'CHF 50.– pro Jahr',
  })

  const textLastName = formatMessage({
    id: 'FormLastName',
    defaultMessage: 'Name*',
  })

  const textFirstName = formatMessage({
    id: 'FormFirstName',
    defaultMessage: 'Vorname*',
  })

  const textPartner = formatMessage({
    id: 'FormPartner',
    defaultMessage: 'Partner/in',
  })

  const textDateOfBirth = formatMessage({
    id: 'FormDateOfBirth',
    defaultMessage: 'Geburtsdatum',
  })

  const textStreet = formatMessage({
    id: 'FormStreet',
    defaultMessage: 'Strasse/Nr.*',
  })

  const textZip = formatMessage({
    id: 'FormZip',
    defaultMessage: 'PLZ/Wohnort*',
  })

  const textEmail = formatMessage({
    id: 'FormEmail',
    defaultMessage: 'E-Mail*',
  })

  const textPhone = formatMessage({
    id: 'FormPhone',
    defaultMessage: 'Telefonnummer*',
  })

  const textNotes = formatMessage({
    id: 'FormNotes',
    defaultMessage: 'Bemerkungen',
  })

  const textNewsletter = formatMessage({
    id: 'FormNewsletter',
    defaultMessage:
      'Hiermit melde ich mich für den Newsletter des Kunstvereins und des Kunstmuseums St.Gallen an.',
  })

  const textSubmit = formatMessage({
    id: 'FormSubmit',
    defaultMessage: 'Senden',
  })

  const textInputRequired = formatMessage({
    id: 'FormInputRequired',
    defaultMessage: 'Bitte füllen Sie dieses Feld aus.',
  })

  const textInputEmailNotValid = formatMessage({
    id: 'FormInputEmailNotValid',
    defaultMessage: 'Geben Sie bitte eine gültige E-Mail Adresse an.',
  })

  const textProcessing = formatMessage({
    id: 'FormInputProcessing',
    defaultMessage:
      'Das Formular wird gerade versendet. Bitte haben Sie einen Moment Geduld.',
  })

  const requiredHint = (
    <div className="BlockMembershipForm__error">{textInputRequired}</div>
  )

  const emailNotValidHint = (
    <div className="BlockMembershipForm__error">{textInputEmailNotValid}</div>
  )

  const processingHint = (
    <div className="BlockMembershipForm__server__processing">
      {textProcessing}
    </div>
  )

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="BlockMembershipForm__list">
        {textType}:
        <div className="BlockMembershipForm__list__item">
          <input
            className="BlockMembershipForm__list__item__input"
            type="radio"
            id="single"
            value="single"
            {...register('type', { required: true })}
          />
          <label
            className="BlockMembershipForm__list__item__label"
            htmlFor="single"
          >
            <span className="BlockMembershipForm__list__item__label__left">
              {textTypeSingle}
            </span>
            <span className="BlockMembershipForm__list__item__label__right">
              {textTypeSinglePrice}
            </span>
          </label>
        </div>
        <div className="BlockMembershipForm__list__item">
          <input
            className="BlockMembershipForm__list__item__input"
            type="radio"
            id="couple"
            value="couple"
            {...register('type', { required: true })}
          />
          <label
            className="BlockMembershipForm__list__item__label"
            htmlFor="couple"
          >
            <span className="BlockMembershipForm__list__item__label__left">
              {textTypeCouple}
            </span>
            <span className="BlockMembershipForm__list__item__label__right">
              {textTypeCouplePrice}
            </span>
          </label>
        </div>
        <div className="BlockMembershipForm__list__item">
          <input
            className="BlockMembershipForm__list__item__input"
            type="radio"
            id="family"
            value="family"
            {...register('type', { required: true })}
          />
          <label
            className="BlockMembershipForm__list__item__label"
            htmlFor="family"
          >
            <span className="BlockMembershipForm__list__item__label__left">
              {textTypeFamily}
            </span>
            <span className="BlockMembershipForm__list__item__label__right">
              {textTypeFamilyPrice}
            </span>
          </label>
        </div>
        <div className="BlockMembershipForm__list__item">
          <input
            className="BlockMembershipForm__list__item__input"
            type="radio"
            id="junior"
            value="junior"
            {...register('type', { required: true })}
          />
          <label
            className="BlockMembershipForm__list__item__label"
            htmlFor="junior"
          >
            <span className="BlockMembershipForm__list__item__label__left">
              {textTypeJunior}
            </span>
            <span className="BlockMembershipForm__list__item__label__right">
              {textTypeJuniorPrice}
            </span>
          </label>
        </div>
        <div className="BlockMembershipForm__list__item">
          <input
            className="BlockMembershipForm__list__item__input"
            type="radio"
            id="artist"
            value="artist"
            {...register('type', { required: true })}
          />
          <label
            className="BlockMembershipForm__list__item__label"
            htmlFor="artist"
          >
            <span className="BlockMembershipForm__list__item__label__left">
              {textTypeArtist}
            </span>
            <span className="BlockMembershipForm__list__item__label__right">
              {textTypeArtistPrice}
            </span>
          </label>
        </div>
        {errors.type && requiredHint}
      </div>

      {/* fake field to catch bots and spammers */}
      <div className="BlockMembershipForm__row__handle">
        <input
          type="text"
          id="handle"
          aria-hidden="true"
          tabIndex={-1}
          {...register('handle')}
        />
      </div>

      {/* locale field, so the backend returns in correct language */}
      <input type="hidden" value={locale} {...register('locale')} />

      <div className="BlockMembershipForm__row">
        <input
          {...register('lastName', { required: true })}
          onFocus={focusHandler('lastName')}
          onBlur={blurHandler('lastName')}
          placeholder={focusFields.lastName ? '' : textLastName}
        />
        {errors.lastName && requiredHint}
      </div>

      <div className="BlockMembershipForm__row">
        <input
          {...register('firstName', { required: true })}
          onFocus={focusHandler('firstName')}
          onBlur={blurHandler('firstName')}
          placeholder={focusFields.firstName ? '' : textFirstName}
        />
        {errors.firstName && requiredHint}
      </div>

      <div className="BlockMembershipForm__row">
        <input
          {...register('partner')}
          onFocus={focusHandler('partner')}
          onBlur={blurHandler('partner')}
          placeholder={focusFields.partner ? '' : textPartner}
        />
      </div>

      <div className="BlockMembershipForm__row">
        <input
          {...register('dateOfBirth')}
          onFocus={focusHandler('dateOfBirth')}
          onBlur={blurHandler('dateOfBirth')}
          placeholder={focusFields.dateOfBirth ? '' : textDateOfBirth}
        />
      </div>

      <div className="BlockMembershipForm__row">
        <input
          {...register('street', { required: true })}
          onFocus={focusHandler('street')}
          onBlur={blurHandler('street')}
          placeholder={focusFields.street ? '' : textStreet}
        />
        {errors.street && requiredHint}
      </div>

      <div className="BlockMembershipForm__row">
        <input
          {...register('zip', { required: true })}
          onFocus={focusHandler('zip')}
          onBlur={blurHandler('zip')}
          placeholder={focusFields.zip ? '' : textZip}
        />
        {errors.zip && requiredHint}
      </div>

      <div className="BlockMembershipForm__row">
        <input
          {...register('email', {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          })}
          onFocus={focusHandler('email')}
          onBlur={blurHandler('email')}
          placeholder={focusFields.email ? '' : textEmail}
        />
        {errors.email?.type === 'required' && requiredHint}
        {errors.email?.type === 'pattern' && emailNotValidHint}
      </div>

      <div className="BlockMembershipForm__row">
        <input
          {...register('phone', { required: true })}
          onFocus={focusHandler('phone')}
          onBlur={blurHandler('phone')}
          placeholder={focusFields.phone ? '' : textPhone}
        />
        {errors.phone && requiredHint}
      </div>

      <div className="BlockMembershipForm__row">
        <textarea
          {...register('notes')}
          rows={5}
          cols={20}
          onFocus={focusHandler('notes')}
          onBlur={blurHandler('notes')}
          placeholder={focusFields.notes ? '' : textNotes}
        />
      </div>

      <div className="BlockMembershipForm__list">
        <div className="BlockMembershipForm__list__item">
          <input
            className="BlockMembershipForm__list__item__input"
            type="checkbox"
            id="newsletter"
            value="true"
            {...register('newsletter')}
          />
          <label
            className="BlockMembershipForm__list__item__label"
            htmlFor="newsletter"
          >
            <span className="BlockMembershipForm__list__item__label__left">
              {textNewsletter}
            </span>
          </label>
        </div>
      </div>

      <div className="BlockMembershipForm__row">{textFilloutFields}</div>

      <div className="BlockMembershipForm__submit">
        <input type="submit" value={textSubmit} />
      </div>
    </form>
  )

  let html = form

  if (formSentMessage.success) {
    html = (
      <div className="BlockMembershipForm__server__success">
        {successMsg.split('\n').map((item, key) => (
          <span key={key}>
            {item}
            <br />
          </span>
        ))}
      </div>
    )
  } else if (formSentMessage.errors) {
    const errorHints = Object.values(formSentMessage.errors).map(
      (item: any, index) =>
        item.length > 0 && (
          <div
            key={index}
            className="BlockMembershipForm__server__errors__item"
          >
            {item[0]}
          </div>
        )
    )

    html = (
      <>
        {form}
        {errorHints && (
          <div className="BlockMembershipForm__server__errors">
            {errorHints}
          </div>
        )}
      </>
    )
  } else if (formSentMessage.processing) {
    html = (
      <>
        {form}
        {processingHint}
      </>
    )
  }

  const classes = classnames('BlockMembershipForm', {
    'BlockMembershipForm--processing': formSentMessage.processing,
  })

  return (
    <div className={classes} ref={ref}>
      {html}
    </div>
  )
}

export default connector(BlockMembershipForm)
