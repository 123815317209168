import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'

import './DragCursor.scss'

type Props = {
  coords?: [number, number]
}

const DragCursor = ({ coords }: Props): ReactElement | null => {
  const { formatMessage } = useIntl()

  if (!coords) {
    return null
  }

  const textDrag = formatMessage({
    id: 'Drag',
    defaultMessage: 'Ziehen',
  })

  const style = {
    transform: `translate(${coords[0]}px, ${coords[1]}px)`,
  }

  return (
    <div className="DragCursor" style={style}>
      <div className="DragCursor__inner">{textDrag}</div>
    </div>
  )
}

export default DragCursor
