import React, { ReactElement, useState } from 'react'
import classnames from 'classnames'

import {
  FilterStateType,
  FilterStateIndexType,
  FilterItemType,
} from 'data/types'

import './HeaderFilterSection.scss'

type Props = {
  type: FilterStateIndexType
  items: FilterItemType[]
  selected: FilterStateType
  handleItemClick: (type: FilterStateIndexType, id: string) => () => void
  handleReset?: () => void
}

const HeaderFilterSection = ({
  type,
  items,
  selected,
  handleItemClick,
  handleReset,
}: Props): ReactElement | null => {
  const [isFilterHovered, setIsFilterHovered] = useState(false)

  if (!items && !handleReset) {
    return null
  }

  const isAnyFilterSelected = Object.values(selected).some(
    (item) => item.length > 0
  )

  const onMouseOverItem = () => {
    setIsFilterHovered(true)
  }
  const onMouseLeaveItem = () => {
    setIsFilterHovered(false)
  }

  const itemsRendered = items.map((item: FilterItemType) => {
    const classes = classnames('HeaderFilterSection__item', {
      'HeaderFilterSection__item--selected': selected[type].includes(item.id),
    })

    return (
      <button
        key={item.id}
        className={classes}
        onClick={handleItemClick(type, item.id)}
        onMouseOver={onMouseOverItem}
        onFocus={onMouseOverItem}
        onMouseLeave={onMouseLeaveItem}
        onBlur={onMouseLeaveItem}
      >
        <span>{item.title}</span>
      </button>
    )
  })

  const resetButton = handleReset && (
    <button className="HeaderFilterSection__reset" onClick={handleReset}>
      Reset
    </button>
  )

  return (
    <div
      className={classnames(
        'HeaderFilterSection',
        { 'HeaderFilterSection--active': isAnyFilterSelected },
        { 'HeaderFilterSection--hover': isFilterHovered }
      )}
    >
      {(itemsRendered || handleReset) && (
        <div className="HeaderFilterSection__items">
          {itemsRendered}
          {resetButton}
        </div>
      )}
    </div>
  )
}

export default HeaderFilterSection
