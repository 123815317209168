import React, { ReactElement } from 'react'

import BlockMembershipForm from './BlockMembershipForm'
import BlockPersonGallery from './BlockPersonGallery'
import BlockImage from './BlockImage'
import BlockSlideshow from './BlockSlideshow'
import BlockSubtitle from './BlockSubtitle'
import BlockText from './BlockText'
import BlockVideo from './BlockVideo'

type Props = {
  contents: any
}

const Blocks = ({ contents }: Props): ReactElement => {
  const blocks = contents.map((item: any) => {
    const { __typename: type, blockHidden } = item

    if (blockHidden) {
      return null
    }

    if (type === 'contents_subtitle_BlockType') {
      return <BlockSubtitle {...item} key={item.id} />
    } else if (type === 'contents_text_BlockType') {
      return <BlockText {...item} key={item.id} />
    } else if (type === 'contents_image_BlockType') {
      return <BlockImage {...item} key={item.id} />
    } else if (type === 'contents_slideshow_BlockType') {
      return <BlockSlideshow {...item} key={item.id} />
    } else if (type === 'contents_video_BlockType') {
      return <BlockVideo {...item} key={item.id} />
    } else if (type === 'contents_persongallery_BlockType') {
      return <BlockPersonGallery {...item} key={item.id} />
    } else if (type === 'contents_membershipForm_BlockType') {
      return <BlockMembershipForm {...item} key={item.id} />
    } else {
      return null
    }
  })

  return <>{blocks}</>
}

export default Blocks
