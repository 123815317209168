import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import SLIDESHOW_QUERY from 'data/queries/slideshow'
import Slideshow from './Slideshow'
import Loading from 'view/components/loading/Loading'
import NotFound from 'view/content/not-found/NotFoundProvider'

const SlideshowProvider = (): ReactElement => {
  const { slug } = useParams()

  const { data, loading } = useQuery(SLIDESHOW_QUERY, {
    variables: {
      slug,
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (loading) {
    return <Loading />
  }

  if (data && data.slideshow) {
    return <Slideshow {...data.slideshow} />
  }

  return <NotFound />
}

export default SlideshowProvider
