import { gql } from '@apollo/client'

const PERSON_GALLERY_QUERY = gql`
  query PersonGalleryQuery($slug: [String], $siteId: [QueryArgument]) {
    gallery: entry(section: ["personGallery"], slug: $slug, siteId: $siteId) {
      id
      title
      ... on personGallery_personGallery_Entry {
        people {
          ... on people_person_BlockType {
            id
            blockHidden
            blockImage {
              id
              url @transform(handle: "large")
              title
              width
              height
            }
            blockCaption
          }
        }
      }
    }
  }
`

export default PERSON_GALLERY_QUERY
