import { AnyAction } from 'redux'

import { BASE_URL } from 'Constants'
import { META_DATA_LOAD } from './types'

export type MetaStateType = {
  title: string
  description: string
  imageUrl: string
  url: string | undefined
  googleAnalyticsId: string
  membershipFormSuccessMsg: string
}

const initialState: MetaStateType = {
  title: '',
  description: '',
  imageUrl: '',
  url: BASE_URL,
  googleAnalyticsId: '',
  membershipFormSuccessMsg: '',
}

export default function (
  state = initialState,
  action: AnyAction
): MetaStateType {
  switch (action.type) {
    case META_DATA_LOAD: {
      const { data } = action.payload

      if (data) {
        const {
          defaultMetaTitle,
          defaultMetaDescription,
          defaultMetaImage,
          googleAnalyticsId,
          membershipFormSuccessMsg,
        } = data

        return {
          ...state,
          title: defaultMetaTitle || state.title,
          description: defaultMetaDescription || state.description,
          imageUrl:
            (defaultMetaImage &&
              defaultMetaImage[0] &&
              defaultMetaImage[0].url) ||
            '',
          googleAnalyticsId: googleAnalyticsId || state.googleAnalyticsId,
          membershipFormSuccessMsg:
            membershipFormSuccessMsg || state.membershipFormSuccessMsg,
        }
      }

      return state
    }

    default:
      return state
  }
}
