import classnames from 'classnames'
import React, { createRef, ReactElement } from 'react'
import { animateScroll } from 'react-scroll'
import { useIntl } from 'react-intl'
import Sticky from 'react-stickynode'
import { ActionCreator, Action } from 'redux'
import striptags from 'striptags'

import { BASE_URL } from 'Constants'
import { ImageType, VideoType } from 'data/types'
import findFirstImage from './findFirstImage'
import { trackPage } from 'view/components/analytics/Analytics'
import Blocks from 'view/components/blocks/Blocks'
import Image from 'view/components/image/Image'
import Video from 'view/components/video/Video'

import './Article.scss'

type Props = {
  index: number
  locale: string
  id: number
  isOpen: boolean
  isFirst: boolean
  isLast: boolean
  slug: string
  headline: string
  lead: string
  kind: string
  previewTextColor: string
  previewImage: ImageType[]
  previewVideo: VideoType[]
  previewVideoWidth: number
  previewVideoHeight: number
  contents: any[]
  handleDetailOpen: (id: number) => void
  handleDetailClose: (id: number) => void
  actionShareOpenDialog: ActionCreator<Action<any>>
}

const Article = ({
  index,
  locale,
  id,
  isOpen,
  isFirst,
  isLast,
  slug,
  headline,
  lead,
  kind,
  previewTextColor,
  previewImage,
  previewVideo,
  previewVideoWidth,
  previewVideoHeight,
  contents,
  handleDetailOpen,
  handleDetailClose,
  actionShareOpenDialog,
}: Props): ReactElement => {
  const ref = createRef<HTMLDivElement>()
  const { formatMessage } = useIntl()

  const handleOpen = () => {
    trackPage(`/${locale}/article/${slug}`)

    handleDetailOpen(id)

    if (ref && ref.current) {
      animateScroll.scrollTo(ref.current.offsetTop, {
        duration: 600,
        smooth: 'easeOutQuad',
      })
    }
  }

  const handleClose = () => {
    handleDetailClose(id)

    if (ref && ref.current) {
      animateScroll.scrollTo(ref.current.offsetTop, {
        duration: 600,
        smooth: 'easeOutQuad',
      })
    }
  }

  const headlineClean = striptags(headline, ['br', 'em', 'i', 'sup', 'sub'])
  const leadClean = striptags(lead, ['br', 'em', 'i', 'p', 'sup', 'sub'])
  const coverVideo = previewVideo && previewVideo.length > 0 && previewVideo[0]
  const coverImage = previewImage && previewImage.length > 0 && previewImage[0]
  let cover = null

  const textShare = formatMessage({
    id: 'Share',
    defaultMessage: 'Teilen',
  })

  const handleShareClick = () => {
    actionShareOpenDialog({
      headline: striptags(headline),
      lead: striptags(lead),
      image: coverImage ? coverImage.url : findFirstImage(contents),
      url: `${BASE_URL}/${locale}/article/${slug}`,
    })
  }

  if (coverImage) {
    cover = (
      <div className="Article__preview__cover">
        <figure className="Article__preview__cover__figure">
          <div
            className="Article__preview__cover__ratio"
            style={{
              paddingTop: `${(coverImage.height / coverImage.width) * 100}%`,
            }}
          />
          <Image image={coverImage} className="Article__preview__cover__img" />
        </figure>
      </div>
    )
  } else if (coverVideo) {
    const coverVideoRatio =
      previewVideoHeight / (previewVideoWidth > 0 ? previewVideoWidth : 1)

    cover = (
      <div className="Article__preview__cover">
        <Video
          video={coverVideo}
          ratio={coverVideoRatio}
          threshold={0.5}
          classNames={['Article__preview__cover__video__container']}
        />
      </div>
    )
  }

  const blocks = <Blocks contents={contents} />
  const classes = classnames(
    'Article',
    `Article--color-${previewTextColor}`,
    { 'Article--open': isOpen },
    { 'Article--first': isFirst },
    { 'Article--last': isLast }
  )

  return (
    <article className={classes} ref={ref}>
      <div className="Article__inner">
        {!isOpen && (
          <div className="Article__preview">
            <button className="Article__preview__button" onClick={handleOpen}>
              <div className="Article__preview__left">
                <div className="Article__preview__meta">{kind}</div>
              </div>

              <div className="Article__preview__center">
                <div className="Article__preview__headline">
                  <h1 dangerouslySetInnerHTML={{ __html: headlineClean }} />
                </div>
                <div
                  className="Article__preview__lead"
                  dangerouslySetInnerHTML={{ __html: leadClean }}
                />
              </div>

              <div className="Article__preview__right">
                <div className="Article__preview__right__open">
                  <svg viewBox="0 0 60 60">
                    <line x1="1.7" y1="30" x2="57.8" y2="30" />
                    <line x1="29.7" y1="2" x2="29.7" y2="58" />
                  </svg>
                </div>
              </div>

              <div className="Article__preview__content">
                {cover}
                <div className="Article__preview__overlay" />
              </div>
            </button>
          </div>
        )}

        {isOpen && (
          <div className="Article__detail">
            <div className="Article__detail__border__top" />

            <Sticky
              top=".Header"
              bottomBoundary={`#article-sticky-barrier-${index}`}
            >
              <button
                className="Article__detail__button__close"
                onClick={handleClose}
              >
                <svg viewBox="0 0 60 60">
                  <line x1="9.9" y1="10.2" x2="49.6" y2="49.8" />
                  <line x1="49.6" y1="10.2" x2="9.9" y2="49.8" />
                </svg>
              </button>
            </Sticky>

            <div className="Article__detail__left">
              <div className="Article__detail__meta">{kind}</div>
            </div>

            <div className="Article__detail__center">
              <button
                className="Article__detail__center__close"
                onClick={handleClose}
              >
                <div className="Article__detail__headline">
                  <h1 dangerouslySetInnerHTML={{ __html: headlineClean }} />
                </div>
                <div
                  className="Article__detail__lead"
                  dangerouslySetInnerHTML={{ __html: leadClean }}
                />
              </button>
            </div>

            <div className="Article__detail__content">{blocks}</div>

            <div
              className="Article__detail__sticky__barrier"
              id={`article-sticky-barrier-${index}`}
            />

            <button
              className="Article__detail__share"
              onClick={handleShareClick}
            >
              {textShare}
            </button>

            <div className="Article__detail__border__bottom" />
          </div>
        )}
      </div>
    </article>
  )
}

export default Article
