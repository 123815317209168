import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { RootState } from 'data/redux/rootReducer'
import { shareCloseDialog } from 'data/redux/share/actions'
import Share from './Share'

const mapStateToProps = (state: RootState) => ({
  open: state.share.open,
  content: state.share.content,
  imageFallback: state.meta.imageUrl,
})

const mapDispatchToProps = {
  actionShareCloseDialog: shareCloseDialog,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ShareProviderProps = ConnectedProps<typeof connector>

const ShareProvider = ({
  open,
  content,
  imageFallback,
  actionShareCloseDialog,
}: ShareProviderProps): ReactElement => {
  return (
    <Share
      open={open}
      content={content}
      imageFallback={imageFallback}
      actionShareCloseDialog={actionShareCloseDialog}
    />
  )
}

export default connector(ShareProvider)
