import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router-dom'

import { BASE_URL } from 'Constants'
import { findFirstSupported, mapLangToSiteId } from 'misc/i18n'
import { RootState } from 'data/redux/rootReducer'
import { shareOpenDialog } from 'data/redux/share/actions'
import { HOME_CATEGORY_QUERY, HOME_SEARCH_QUERY } from 'data/queries/home'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Home from './Home'
import Loading from 'view/components/loading/Loading'
import NotFound from 'view/content/not-found/NotFoundProvider'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
  searchValue: state.global.search,
  filters: state.global.filters,
  closeAllArticles: state.global.closeAllArticles,
})

const mapDispatchToProps = {
  actionShareOpenDialog: shareOpenDialog,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type HomeProviderProps = ConnectedProps<typeof connector>

const HomeProvider = ({
  locale,
  searchValue,
  filters,
  closeAllArticles,
  actionShareOpenDialog,
}: HomeProviderProps): ReactElement | null => {
  const { lang } = useParams()

  // flatten array to find the id | undefined
  const categoryId = Object.values(filters).flat().shift()

  const { data, loading } = useQuery(HOME_SEARCH_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
      search: searchValue,
      categoryPurpose: filters.purpose.length === 0 ? null : filters.purpose,
      categoryOffer: filters.offer.length === 0 ? null : filters.offer,
    },
  })

  const { data: data1 } = useQuery(HOME_CATEGORY_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
      id: categoryId,
    },
    skip: !categoryId,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const helmetParams = {
    url: `${BASE_URL}/${locale}`,
  }

  if (loading) {
    return (
      <>
        <Helmet {...helmetParams} />
        <Loading />
      </>
    )
  }

  // check lang param is a supported language
  if (data && lang && findFirstSupported([lang])) {
    return (
      <>
        <Helmet {...helmetParams} />
        <Home
          category={(data1 && data1.category) || false}
          closeAllArticles={closeAllArticles}
          locale={locale}
          results={data.results}
          actionShareOpenDialog={actionShareOpenDialog}
        />
      </>
    )
  }

  return <NotFound />
}

export default connector(HomeProvider)
