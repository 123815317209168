import classnames from 'classnames'
import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { useMatch } from 'react-router-dom'

import { RootState } from 'data/redux/rootReducer'

import './CookieBanner.scss'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const CookieBanner = ({ locale }: PropsFromRedux): ReactElement | null => {
  const [accepted, setAccepted] = useState(false)
  const [acceptedCookie, setAcceptedCookie] = useState(false)
  const { formatMessage } = useIntl()
  const isPage = useMatch('/:lang/page/:slug')

  const toggleAcceptedClassToBody = (isAccepted = false) => {
    if (isAccepted) {
      window.document.body.classList.add('h-state--cookie-accepeted')
    } else {
      window.document.body.classList.remove('h-state--cookie-accepeted')
    }
  }

  useEffect(() => {
    const acceptedItem =
      localStorage && localStorage.getItem('kunstverein_privacy_accepted')
    const isAccepted = acceptedItem === 'yeah'

    setAcceptedCookie(isAccepted)
    toggleAcceptedClassToBody(isAccepted)
  }, [setAcceptedCookie])

  const acceptCookies = () => {
    localStorage.setItem('kunstverein_privacy_accepted', 'yeah')
    setAccepted(true)
    toggleAcceptedClassToBody(true)
  }

  const textPrivacyPolicy = formatMessage({
    id: 'PrivacyPolicy',
    defaultMessage: 'Datenschutz:',
  })

  const textPrivacyPolicyRead = formatMessage({
    id: 'PrivacyPolicyRead',
    defaultMessage: 'Lesen',
  })

  const textPrivacyPolicyOk = formatMessage({
    id: 'PrivacyPolicyOk',
    defaultMessage: 'OK',
  })

  const classes = classnames('CookieBanner', {
    'CookieBanner--color-white': isPage,
  })

  if (!accepted && !acceptedCookie) {
    return (
      <div className={classes}>
        <span className="CookieBanner__text">
          {textPrivacyPolicy}{' '}
          <Link to={`/${locale}/page/privacy`}>{textPrivacyPolicyRead}</Link>
        </span>{' '}
        /&nbsp;
        <button onClick={acceptCookies} className="CookieBanner__button">
          {textPrivacyPolicyOk}
        </button>
      </div>
    )
  }

  return null
}

export default connector(CookieBanner)
