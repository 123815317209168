/* eslint-disable react/no-children-prop */
import React, { ReactElement } from 'react'
import { Routes, Route } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import classnames from 'classnames'

import { RootState } from 'data/redux/rootReducer'
import Analytics from 'view/components/analytics/Analytics'
import CookieBanner from 'view/components/cookie-banner/CookieBanner'
import Footer from 'view/components/footer/FooterProvider'
import Header from 'view/components/header/HeaderProvider'
import Share from 'view/components/share/ShareProvider'

import Home from 'view/content/home/HomeProvider'
import Agenda from 'view/content/agenda/AgendaProvider'
import Detail from 'view/content/detail/DetailProvider'
import NotFound from 'view/content/not-found/NotFoundProvider'
import Page from 'view/content/page/PageProvider'
import PersonGallery from 'view/content/person-gallery/PersonGalleryProvider'
import Slideshow from 'view/content/slideshow/SlideshowProvider'

import 'style/globals/_index.scss'

const mapStateToProps = (state: RootState) => ({
  shareOpen: state.share.open,
})

const connector = connect(mapStateToProps)
type AppProps = ConnectedProps<typeof connector>

const App = ({ shareOpen }: AppProps): ReactElement => {
  const classes = classnames('App', { 'App--blur': shareOpen })

  return (
    <>
      <Analytics />
      <main className={classes}>
        <Header />
        <Routes>
          <Route path="/:lang" element={<Home />} />
          <Route path="/:lang/article/:slug" element={<Detail />} />
          <Route path="/:lang/agenda" element={<Agenda />} />
          <Route path="/:lang/page/:slug" element={<Page />} />
          <Route path="/:lang/slideshow/:slug" element={<Slideshow />} />
          <Route
            path="/:lang/person-gallery/:slug"
            element={<PersonGallery />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <CookieBanner />
      </main>
      <Share />
    </>
  )
}

export default connector(App)
