import { gql } from '@apollo/client'

const AGENDA_QUERY = gql`
  query AgendaQuery(
    $startDate: [QueryArgument]
    $search: String
    $siteId: [QueryArgument]
  ) {
    results: entries(
      section: "articles"
      type: "agenda"
      search: $search
      agendaDate: $startDate
      orderBy: "agendaDate asc, agendaTimeStart asc"
      siteId: $siteId
    ) {
      id
      siteId
      ... on articles_agenda_Entry {
        headline
        subtitle
        lead
        agendaDate @formatDateTime(format: "d.m.Y", timezone: "Europe/Zurich")
        agendaTimeStart
          @formatDateTime(format: "H.i", timezone: "Europe/Zurich")
        agendaTimeEnd @formatDateTime(format: "H.i", timezone: "Europe/Zurich")
        agendaLocation
      }
    }
  }
`

export default AGENDA_QUERY
