import { gql } from '@apollo/client'

const FOOTER_QUERY = gql`
  query FooterQuery($siteId: [QueryArgument]) {
    navigation: entries(section: "navigation", siteId: $siteId) {
      id
      siteId
      title
      __typename
      ... on navigation_navigation_Entry {
        navigationMenu
        navigationRelatedPage {
          id
          slug
        }
      }
      ... on navigation_externalLink_Entry {
        navigationMenu
        navigationExternalUrl
      }
    }
  }
`

export default FOOTER_QUERY
