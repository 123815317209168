import React, { ReactElement } from 'react'

import { CategoryType } from 'data/types'

import './Category.scss'

const Category = ({
  categoryDescription,
}: CategoryType): ReactElement | null => {
  if (!categoryDescription) {
    return null
  }

  return (
    <div className="Category">
      <div className="Category__inner">
        <div
          className="Category__text"
          dangerouslySetInnerHTML={{ __html: categoryDescription }}
        />
      </div>
    </div>
  )
}

export default Category
