import { useQuery } from '@apollo/client'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { mapLangToSiteId } from 'misc/i18n'
import { RootState } from 'data/redux/rootReducer'
import FOOTER_QUERY from 'data/queries/footer'
import Footer from './Footer'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const FooterProvider = ({ locale }: PropsFromRedux) => {
  const { data } = useQuery(FOOTER_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  if (data && data.navigation) {
    return <Footer locale={locale} {...data} />
  }

  return null
}

export default connector(FooterProvider)
