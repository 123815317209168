import React, { ReactElement } from 'react'
import classnames from 'classnames'

import './BlockText.scss'

type Props = {
  blockNoPaddingBottom: boolean
  blockIndent: boolean
  blockContent: string
}

const BlockText = ({
  blockNoPaddingBottom,
  blockIndent,
  blockContent,
}: Props): ReactElement => (
  <div
    className={classnames(
      'BlockText',
      { 'BlockText--no-padding-bottom': blockNoPaddingBottom },
      { 'BlockText--indent': blockIndent }
    )}
  >
    <div
      className="BlockText__inner"
      dangerouslySetInnerHTML={{ __html: blockContent }}
    />
  </div>
)

export default BlockText
