import { gql } from '@apollo/client'

const HEADER_QUERY = gql`
  query HeaderQuery($siteId: [QueryArgument]) {
    global: globalSet(handle: "globalConfig", siteId: $siteId) {
      id
      siteId
      ... on globalConfig_GlobalSet {
        siteName
      }
    }
    categoryPurpose: categories(group: "categoryPurpose", siteId: $siteId) {
      id
      siteId
      title
    }
    categoryOffer: categories(group: "categoryOffer", siteId: $siteId) {
      id
      siteId
      title
    }
  }
`

export default HEADER_QUERY
