/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  MouseEvent,
  useEffect,
  useState,
  useRef,
  ReactElement,
} from 'react'
import Swiper from 'react-id-swiper'
import classnames from 'classnames'
import striptags from 'striptags'

import { ImageType, SlideshowType } from 'data/types'
import DragCursor from 'view/components/drag-cursor/DragCursor'
import Image from 'view/components/image/Image'

import './BlockSlideshow.scss'

type Props = {
  blockStyle: string
  blockSlideshow: SlideshowType[]
  blockCaption: string
}

const BlockSlideshow = ({
  blockStyle,
  blockSlideshow,
  blockCaption,
}: Props): ReactElement | null => {
  const ref = useRef<HTMLDivElement>(null)
  const refSwiper = useRef<any>(null)
  const [hover, setHover] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [mouseCoords, setMouseCoords] = useState<[number, number]>([0, 0])

  useEffect(() => {
    if (refSwiper.current !== null && refSwiper.current.swiper !== null) {
      refSwiper.current.swiper.init()
    }
  }, [])

  const onMouseOver = () => setHover(true)
  const onMouseLeave = () => setHover(false)
  const onMouseMove = (ev: MouseEvent<HTMLDivElement>) => {
    if (ref && ref.current) {
      const bounds = ref.current?.getBoundingClientRect()
      const mouseCoords: [number, number] = [
        ev.clientX - bounds.left,
        ev.clientY - bounds.top,
      ]

      setMouseCoords(mouseCoords)
    }
  }

  const onLoad = () => {
    if (refSwiper && refSwiper.current && refSwiper.current.swiper) {
      refSwiper.current.swiper.update()
    }
  }

  const captionClean = striptags(blockCaption, [
    'p',
    'br',
    'em',
    'i',
    'a',
    'ul',
    'li',
    'sup',
    'sub',
  ])
  const relatedSlideshow = blockSlideshow.length > 0 && blockSlideshow[0]

  if (!relatedSlideshow) {
    return null
  }

  // render items (slides)
  const items = relatedSlideshow.slideshowContents.map(
    (image: ImageType) =>
      image && (
        <Image
          key={image.id}
          image={image}
          className="BlockSlideshow__img"
          onLoad={onLoad}
        />
      )
  )

  const caption = captionClean && (
    <div
      className="BlockSlideshow__caption"
      dangerouslySetInnerHTML={{ __html: captionClean }}
    />
  )

  const swiperParams: any = {
    freeMode: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      touchStart: () => setDragging(true),
      touchEnd: (swiper: typeof Swiper, ev: PointerEvent) => {
        if (ref && ref.current) {
          const bounds = ref.current?.getBoundingClientRect()
          const mouseCoords: [number, number] = [
            ev.clientX - bounds.left,
            ev.clientY - bounds.top,
          ]

          setMouseCoords(mouseCoords)
        }

        setDragging(false)
      },
    },
  }

  return (
    <div
      ref={ref}
      className={classnames(
        'BlockSlideshow',
        `BlockSlideshow--size-${blockStyle}`,
        { 'BlockSlideshow--dragging': dragging }
      )}
    >
      <div
        className="BlockSlideshow__inner"
        onMouseOver={onMouseOver}
        onFocus={onMouseOver}
        onMouseLeave={onMouseLeave}
        onBlur={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <Swiper ref={refSwiper} {...swiperParams}>
          {items}
        </Swiper>

        {hover && <DragCursor coords={mouseCoords} />}
      </div>
      {caption}
    </div>
  )
}

export default BlockSlideshow
