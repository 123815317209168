import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { BASE_URL } from 'Constants'
import Helmet from 'view/components/helmet/DefaultHelmet'
import AgendaItem from 'view/content/agenda/AgendaItem'
import Article from 'view/content/home/Article'
import ArticleSplit from 'view/content/home/ArticleSplit'
import CallToAction from 'view/content/home/CallToAction'
import Testimonial from 'view/content/home/Testimonial'

import { DetailProviderProps } from './DetailProvider'

import './Detail.scss'

interface Props extends DetailProviderProps {
  article: any
}

const Detail = ({
  locale,
  actionShareOpenDialog,
  article,
}: Props): ReactElement | null => {
  const navigate = useNavigate()
  const { __typename: type } = article

  const handleDetailOpen = () => {}
  const handleDetailClose = () => navigate(`/${locale}`)
  const helmetImages = []

  if (type === 'articles_article_Entry') {
    const coverImage =
      article.previewImage &&
      article.previewImage.length > 0 &&
      article.previewImage[0]

    if (coverImage) {
      helmetImages.push(coverImage)
    }

    const helmetParams = {
      title: article.headline,
      description: article.lead,
      url: `${BASE_URL}/${locale}/article/${article.slug}`,
      images: helmetImages,
    }

    return (
      <>
        <Helmet {...helmetParams} />
        <section className="Detail">
          <div className="Detail__inner">
            <Article
              locale={locale}
              isOpen={true}
              isLast={true}
              handleDetailOpen={handleDetailOpen}
              handleDetailClose={handleDetailClose}
              actionShareOpenDialog={actionShareOpenDialog}
              {...article}
            />
          </div>
        </section>
      </>
    )
  } else if (type === 'articles_articleSplit_Entry') {
    const coverImage =
      article.previewImage &&
      article.previewImage.length > 0 &&
      article.previewImage[0]

    if (coverImage) {
      helmetImages.push(coverImage)
    }

    const helmetParams = {
      title: article.headline,
      description: article.lead,
      url: `${BASE_URL}/${locale}/article/${article.slug}`,
      images: helmetImages,
    }

    return (
      <>
        <Helmet {...helmetParams} />
        <section className="Detail">
          <div className="Detail__inner">
            <ArticleSplit
              locale={locale}
              isOpen={true}
              isLast={true}
              handleDetailOpen={handleDetailOpen}
              handleDetailClose={handleDetailClose}
              actionShareOpenDialog={actionShareOpenDialog}
              {...article}
            />
          </div>
        </section>
      </>
    )
  } else if (type === 'articles_callToAction_Entry') {
    return <CallToAction {...article} />
  } else if (type === 'articles_testimonial_Entry') {
    return <Testimonial {...article} />
  } else if (type === 'articles_agenda_Entry') {
    return <AgendaItem isDark={true} {...article} />
  }

  return null
}

export default Detail
