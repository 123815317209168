import { useQuery } from '@apollo/client'
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'

import { mapLangToSiteId } from 'misc/i18n'
import DETAIL_ARTICLE_QUERY from 'data/queries/detail'
import { RootState } from 'data/redux/rootReducer'
import { shareOpenDialog } from 'data/redux/share/actions'
import Detail from './Detail'
import Loading from 'view/components/loading/Loading'
import NotFound from 'view/content/not-found/NotFoundProvider'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
})

const mapDispatchToProps = {
  actionShareOpenDialog: shareOpenDialog,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type DetailProviderProps = ConnectedProps<typeof connector>

const DetailProvider = ({
  locale,
  actionShareOpenDialog,
}: DetailProviderProps): ReactElement | null => {
  const { slug } = useParams()

  const { data, loading } = useQuery(DETAIL_ARTICLE_QUERY, {
    variables: {
      slug,
      siteId: mapLangToSiteId(locale),
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (loading) {
    return <Loading />
  }

  if (data && data.article) {
    return (
      <Detail
        locale={locale}
        actionShareOpenDialog={actionShareOpenDialog}
        {...data}
      />
    )
  }

  return <NotFound />
}

export default connector(DetailProvider)
