import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { createStore, applyMiddleware, compose, Store } from 'redux'
import ThunkMiddleware from 'redux-thunk'

import { IS_SERVER_SIDE, IS_DEV } from 'Constants'
import { rootReducer } from './rootReducer'

const configureStore = (
  preloadedState: any = {},
  client: ApolloClient<NormalizedCacheObject>
): Store => {
  const middlewares = [ThunkMiddleware.withExtraArgument({ client })]
  let composeEnhancers = compose

  if (!IS_SERVER_SIDE && IS_DEV) {
    composeEnhancers =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  }

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  // Enable Webpack hot module replacement for reducers
  if (module.hot && IS_DEV) {
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

export default configureStore
