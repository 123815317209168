import { AnyAction } from 'redux'

import { defaultLang } from 'misc/i18n'
import {
  GLOBAL_CHANGE_FILTERS,
  GLOBAL_CHANGE_LANGUAGE,
  GLOBAL_CLOSE_ARTICLES,
} from './types'

type GlobalStateType = {
  locale: string
  search: string
  filters: {
    purpose: string[]
    offer: string[]
  }
  closeAllArticles: number
}

const initialState: GlobalStateType = {
  locale: defaultLang,
  search: '',
  filters: {
    purpose: [],
    offer: [],
  },
  closeAllArticles: 0,
}

export default function (
  state = initialState,
  action: AnyAction
): GlobalStateType {
  switch (action.type) {
    case GLOBAL_CHANGE_FILTERS: {
      const { filters, search } = action.payload
      return {
        ...state,
        filters,
        search,
        closeAllArticles: state.closeAllArticles + 1,
      }
    }

    case GLOBAL_CHANGE_LANGUAGE: {
      const { locale } = action.payload

      if (locale === state.locale) {
        return state
      }

      return {
        ...state,
        filters: {
          purpose: [],
          offer: [],
        },
        search: '',
        locale,
      }
    }

    case GLOBAL_CLOSE_ARTICLES: {
      return {
        ...state,
        closeAllArticles: state.closeAllArticles + 1,
      }
    }

    default:
      return state
  }
}
