import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Action, ActionCreator } from 'redux'
import classnames from 'classnames'
import { motion } from 'framer-motion'

import LangSwitcher from 'view/components/lang-switcher/LangSwitcher'

type Props = {
  global: {
    siteName: string
  }
  children: ReactNode
  locale: string
  searchInFocus: boolean
  setSearchInFocusMobile: (state: boolean) => void
  searchPressedEnter: boolean
  actionCloseArticles: ActionCreator<Action<any>>
}

const HeaderSmall = ({
  global,
  children,
  locale,
  searchInFocus,
  setSearchInFocusMobile,
  searchPressedEnter,
  actionCloseArticles,
}: Props): ReactElement => {
  const location = useLocation()
  const navigate = useNavigate()
  const itemsInnerRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [openDone, setOpenDone] = useState(false)
  const [delay, setDelay] = useState(0)

  const handleToggleOpen = () => {
    setDelay(0)

    if (open === false) {
      window.setTimeout(() => setOpenDone(true), 300)

      // scroll back to top of menu
      if (itemsInnerRef && itemsInnerRef.current) {
        itemsInnerRef.current.scrollTop = 0
      }
    } else {
      setOpenDone(false)
      window.setTimeout(() => setSearchInFocusMobile(false), 600)
    }

    setOpen(!open)
  }

  const handleUnderlayClose = () => {
    setDelay(0)
    setOpen(false)
    window.setTimeout(() => setOpenDone(false), 300)
    window.setTimeout(() => setSearchInFocusMobile(false), 600)
  }

  const handleLogoClick = () => {
    actionCloseArticles()
    navigate(`/${locale}`)
    setDelay(0)
    setOpenDone(false)
    window.setTimeout(() => setSearchInFocusMobile(false), 600)
  }

  // every time a new page gets visited,
  // we close the small navigation
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if ((event.key === 'Esc' || event.key === 'Escape') && open) {
        handleUnderlayClose()
      }
    }

    window.addEventListener('keydown', keyDownHandler)

    // close when pressed enter on search input
    if (searchPressedEnter) {
      setOpen(false)
      window.setTimeout(() => setOpenDone(false), 300)
    }

    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [open, setOpen, searchPressedEnter])

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if (!(params.get('s') || searchInFocus)) {
      window.setTimeout(() => setOpenDone(false), 1300)
      setOpen(false)
      setDelay(1)
    }
  }, [location, searchInFocus])

  const classesHeaderSmall = classnames('Header__small__fixed', {
    'Header__small__fixed--open': openDone,
  })

  const menuVariants = {
    open: { y: 0 },
    closed: { y: '-100%' },
  }

  return (
    <>
      <div className="Header__small">
        <div className="Header__small__logo">
          <button className="Header__small__logo__left">
            {(global && global.siteName) || 'Kunstverein St.Gallen'}
          </button>
          <button className="Header__small__logo__right">
            <svg viewBox="0 0 60 60">
              <line x1="9.9" y1="10.2" x2="49.6" y2="49.8" />
              <line x1="49.6" y1="10.2" x2="9.9" y2="49.8" />
            </svg>
          </button>
        </div>
      </div>

      <div className={classesHeaderSmall}>
        <div className="Header__small__logo">
          <button
            className="Header__small__logo__left"
            onClick={handleLogoClick}
          >
            {(global && global.siteName) || 'Kunstverein St.Gallen'}
          </button>
          <button
            className="Header__small__logo__right"
            onClick={handleToggleOpen}
          >
            {open && (
              <svg viewBox="0 0 60 60">
                <line x1="9.9" y1="10.2" x2="49.6" y2="49.8" />
                <line x1="49.6" y1="10.2" x2="9.9" y2="49.8" />
              </svg>
            )}
            {!open && (
              <svg viewBox="0 0 60 60">
                <line x1="1.7" y1="30" x2="57.8" y2="30" />
                <line x1="29.7" y1="2" x2="29.7" y2="58" />
              </svg>
            )}
          </button>
        </div>
      </div>

      <motion.div
        className="Header__small__menu"
        initial="closed"
        animate={open ? 'open' : 'closed'}
        variants={menuVariants}
        transition={{
          type: 'spring',
          damping: 22,
          restDelta: 0.5,
          delay: delay,
        }}
      >
        <LangSwitcher />

        <div className="Header__small__logo">
          <div className="Header__small__logo__left">
            {(global && global.siteName) || 'Kunstverein St.Gallen'}
          </div>
          <div className="Header__small__logo__right">
            <svg viewBox="0 0 60 60">
              <line x1="9.9" y1="10.2" x2="49.6" y2="49.8" />
              <line x1="49.6" y1="10.2" x2="9.9" y2="49.8" />
            </svg>
          </div>
        </div>

        <div className="Header__small__items">
          <div className="Header__small__items__inner" ref={itemsInnerRef}>
            {children}
          </div>
        </div>
      </motion.div>

      {open && (
        <button
          className="Header__small__underlay"
          onClick={handleUnderlayClose}
        />
      )}
    </>
  )
}

export default HeaderSmall
