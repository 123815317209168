import { gql } from '@apollo/client'

const PAGE_QUERY = gql`
  query PageQuery($slug: [String], $siteId: [QueryArgument]) {
    page: entry(section: "pages", slug: $slug, siteId: $siteId) {
      id
      siteId
      ... on pages_pages_Entry {
        headline
        contents {
          __typename
          ... on contents_subtitle_BlockType {
            id
            blockHidden
            blockContent
          }
          ... on contents_text_BlockType {
            id
            blockHidden
            blockNoPaddingBottom
            blockIndent
            blockContent
          }
          ... on contents_image_BlockType {
            id
            blockHidden
            blockStyle
            blockImage {
              id
              url @transform(handle: "large")
              title
              width
              height
            }
            blockCaption
          }
          ... on contents_video_BlockType {
            id
            blockHidden
            blockStyle
            blockVideo {
              id
              url
            }
            blockVideoWidth
            blockVideoHeight
            blockCaption
          }
          ... on contents_slideshow_BlockType {
            id
            blockHidden
            blockStyle
            blockSlideshow {
              id
              ... on slideshow_slideshow_Entry {
                slideshowContents {
                  id
                  url @transform(handle: "large")
                  title
                  width
                  height
                }
              }
            }
            blockCaption
          }
          ... on contents_persongallery_BlockType {
            id
            blockHidden
            blockTitle
            blockGallery {
              id
              ... on personGallery_personGallery_Entry {
                people {
                  ... on people_person_BlockType {
                    id
                    blockHidden
                    blockImage {
                      id
                      url @transform(handle: "large")
                      title
                      width
                      height
                    }
                    blockCaption
                  }
                }
              }
            }
          }
          ... on contents_membershipForm_BlockType {
            id
            blockHidden
          }
        }
      }
    }
  }
`

export default PAGE_QUERY
