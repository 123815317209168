import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { RootState } from 'data/redux/rootReducer'
import NotFound from './NotFound'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const NotFoundProvider = ({ locale }: PropsFromRedux) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <NotFound locale={locale} />
}

export default connector(NotFoundProvider)
