import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'

import { AgendaItemType } from 'data/types'
import AgendaItem from './AgendaItem'

import './Agenda.scss'

type Props = {
  results: AgendaItemType[]
}

const Agenda = ({ results }: Props): ReactElement => {
  const { formatMessage } = useIntl()

  const items = results.map((item: AgendaItemType, index) => (
    <AgendaItem key={item.id} isDark={index % 2 === 0} {...item} />
  ))

  const textNoResultsFound = formatMessage({
    id: 'NoResultsFound',
    defaultMessage: 'Keine Resultate gefunden.',
  })

  const noResultsFound = results.length === 0 && (
    <div className="Agenda__nothingfound">
      <div className="Agenda__nothingfound__inner">
        <div className="Agenda__nothingfound__headline">
          {textNoResultsFound}
        </div>
      </div>
    </div>
  )

  return (
    <section className="Agenda">
      <div className="Agenda__inner">
        {items}
        {noResultsFound}
      </div>
    </section>
  )
}

export default Agenda
