import { Action, ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { ReduxExtraArgumentsType } from 'data/types'
import { META_DATA_LOAD } from './types'
import META_QUERY from 'data/queries/meta'
import { mapLangToSiteId } from 'misc/i18n'

export const metaDataLoad: ActionCreator<
  ThunkAction<Promise<Action>, any, ReduxExtraArgumentsType, Action>
> = (locale: string) => {
  return async (
    dispatch: Dispatch,
    getState: any,
    { client }: ReduxExtraArgumentsType
  ) => {
    const response = await client.query({
      query: META_QUERY,
      variables: {
        siteId: mapLangToSiteId(locale),
      },
    })

    return dispatch({
      type: META_DATA_LOAD,
      payload: { data: response.data.global },
    })
  }
}
