import { Action, ActionCreator } from 'redux'

import { GlobalFilterTypes } from 'data/types'
import {
  GLOBAL_CHANGE_FILTERS,
  GLOBAL_CHANGE_LANGUAGE,
  GLOBAL_CLOSE_ARTICLES,
} from './types'

export const globalChangeFilters: ActionCreator<Action> = (
  filters: GlobalFilterTypes,
  search: string
) => ({
  type: GLOBAL_CHANGE_FILTERS,
  payload: { filters, search },
})

export const globalChangeLanguage: ActionCreator<Action> = (
  locale: string
) => ({
  type: GLOBAL_CHANGE_LANGUAGE,
  payload: { locale },
})

export const globalCloseArticles: ActionCreator<Action> = () => ({
  type: GLOBAL_CLOSE_ARTICLES,
})
