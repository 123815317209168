import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'

import './Loading.scss'

const Loading = (): ReactElement => {
  const { formatMessage } = useIntl()

  const textPageLoading = formatMessage({
    id: 'PageLoading',
    defaultMessage: 'Seite wird geladen…',
  })

  return (
    <div className="Loading">
      <div className="Loading__inner">{textPageLoading}</div>
    </div>
  )
}

export default Loading
