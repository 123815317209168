import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'

import { supportedLangs } from 'misc/i18n'
import { RootState } from 'data/redux/rootReducer'
import { globalChangeLanguage } from 'data/redux/global/actions'
import { metaDataLoad } from 'data/redux/meta/actions'

import './LangSwitcher.scss'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
})

const mapDispatchToProps = {
  actionChangeLanguage: globalChangeLanguage,
  actionMetaDataLoad: metaDataLoad,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const LangSwitcher = ({
  locale,
  actionChangeLanguage,
  actionMetaDataLoad,
}: PropsFromRedux): ReactElement => {
  const navigate = useNavigate()

  const handleLinkClick = (lang: string) => () => {
    navigate(`/${lang}`)

    // change the language, so all components update to new language
    actionChangeLanguage(lang)

    // update meta section, so that helmet meta tags are correct
    actionMetaDataLoad(lang)
  }

  return (
    <div className="LangSwitcher">
      <ul className="LangSwitcher__list">
        {Object.keys(supportedLangs).map((lang) => (
          <li
            key={lang}
            className={`${
              lang === locale
                ? 'LangSwitcher__item LangSwitcher__item--active'
                : 'LangSwitcher__item'
            }`}
          >
            <button
              className="LangSwitcher__link"
              onClick={handleLinkClick(lang)}
            >
              {lang}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default connector(LangSwitcher)
