import moment from 'moment'
import React, { useState, useEffect, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { ActionCreator, Action } from 'redux'

import { CategoryType } from 'data/types'
import AgendaItem from 'view/content/agenda/AgendaItem'
import Article from './Article'
import ArticleSplit from './ArticleSplit'
import CallToAction from './CallToAction'
import Category from './Category'
import Testimonial from './Testimonial'

import './Home.scss'

type Props = {
  category: CategoryType | false
  closeAllArticles: number
  locale: string
  results: any[]
  actionShareOpenDialog: ActionCreator<Action<any>>
}

const Home = ({
  category,
  closeAllArticles,
  locale,
  results,
  actionShareOpenDialog,
}: Props): ReactElement => {
  const [detailOpen, setDetailOpen] = useState<any>({})
  const { formatMessage } = useIntl()

  const handleDetailOpen = (id: number) => {
    setDetailOpen({
      ...detailOpen,
      [id]: true,
    })
  }

  const handleDetailClose = (id: number) => {
    setDetailOpen({
      ...detailOpen,
      [id]: false,
    })
  }

  // close all articles, when reset or any filter
  // is clicked in header
  useEffect(() => {
    setDetailOpen({})
  }, [closeAllArticles, locale])

  const categoryComponent = category && <Category {...category} />
  const resultsCleaned = results.filter((item) => {
    const { __typename: type } = item

    if (type === 'articles_agenda_Entry') {
      // only show agenda events of today or future
      const agendaDateObj = moment(item.agendaDate, 'DD.MM.YYYY')
      if (moment().diff(agendaDateObj, 'days') <= 0) {
        return true
      }

      return false
    }

    return true
  })

  const totalItems = resultsCleaned.length - 1
  const items = resultsCleaned.map((item, index) => {
    const { __typename: type } = item

    if (type === 'articles_article_Entry') {
      return (
        <Article
          key={item.id}
          index={item.id}
          locale={locale}
          isOpen={detailOpen[item.id] || false}
          isFirst={index === 0}
          isLast={index === totalItems}
          handleDetailOpen={handleDetailOpen}
          handleDetailClose={handleDetailClose}
          actionShareOpenDialog={actionShareOpenDialog}
          {...item}
        />
      )
    } else if (type === 'articles_articleSplit_Entry') {
      return (
        <ArticleSplit
          key={item.id}
          index={item.id}
          locale={locale}
          isOpen={detailOpen[item.id] || false}
          isFirst={index === 0}
          isLast={index === totalItems}
          handleDetailOpen={handleDetailOpen}
          handleDetailClose={handleDetailClose}
          actionShareOpenDialog={actionShareOpenDialog}
          {...item}
        />
      )
    } else if (type === 'articles_callToAction_Entry') {
      return <CallToAction key={item.id} {...item} />
    } else if (type === 'articles_testimonial_Entry') {
      return <Testimonial key={item.id} {...item} />
    } else if (type === 'articles_agenda_Entry') {
      return <AgendaItem key={item.id} isDark={index % 2 === 0} {...item} />
    } else {
      return null
    }
  })

  const textNoResultsFound = formatMessage({
    id: 'NoResultsFound',
    defaultMessage: 'Keine Resultate gefunden.',
  })

  const noResultsFound = resultsCleaned.length === 0 && (
    <div className="Home__nothingfound">
      <div className="Home__nothingfound__inner">
        <div className="Home__nothingfound__headline">{textNoResultsFound}</div>
      </div>
    </div>
  )

  return (
    <section className="Home">
      <div className="Home__inner">
        {categoryComponent}
        {items}
        {noResultsFound}
      </div>
    </section>
  )
}

export default Home
