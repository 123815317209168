import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import striptags from 'striptags'
import trim from 'trim'

import { TestimonialType } from 'data/types'
import Image from 'view/components/image/Image'

import './Testimonial.scss'

const Testimonial = ({
  testimonialQuote,
  testimonialAuthor,
  testimonialImage,
}: TestimonialType): ReactElement => {
  const { formatMessage } = useIntl()
  const quoteClean = trim(
    striptags(testimonialQuote, ['br', 'em', 'i', 'sup', 'sub'])
  )
  const authorClean = striptags(testimonialAuthor, [
    'br',
    'em',
    'i',
    'sup',
    'sub',
  ])
  const authorImage =
    testimonialImage && testimonialImage.length > 0 && testimonialImage[0]

  const textQuoteStart = formatMessage({
    id: 'QuoteStart',
    defaultMessage: '«',
  })

  const textQuoteEnd = formatMessage({
    id: 'QuoteEnd',
    defaultMessage: '»',
  })

  const quote = quoteClean && (
    <div className="Testimonial__quote">
      {textQuoteStart}
      <span dangerouslySetInnerHTML={{ __html: quoteClean }} />
      {textQuoteEnd}
    </div>
  )

  const author = authorClean && (
    <div className="Testimonial__author">
      <span dangerouslySetInnerHTML={{ __html: authorClean }} />
    </div>
  )

  const image = authorImage && (
    <div className="Testimonial__thumb">
      <figure className="Testimonial__thumb__figure">
        <div
          className="Testimonial__thumb__ratio"
          style={{
            paddingTop: `${(authorImage.height / authorImage.width) * 100}%`,
          }}
        />
        <Image image={authorImage} className="Testimonial__thumb__img" />
      </figure>
    </div>
  )

  return (
    <div className="Testimonial">
      <div className="Testimonial__inner">
        <div className="Testimonial__left">{image}</div>
        <div className="Testimonial__right">
          {quote}
          {author}
        </div>
      </div>
    </div>
  )
}

export default Testimonial
