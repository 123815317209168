import React, { ReactElement } from 'react'
import striptags from 'striptags'

import './BlockSubtitle.scss'

type Props = {
  blockContent: string
}

const BlockSubtitle = ({ blockContent }: Props): ReactElement | null => {
  const subtitleClean = striptags(blockContent, ['br', 'em', 'i', 'sup', 'sub'])

  if (subtitleClean === '') {
    return null
  }

  return (
    <div className="BlockSubtitle">
      <h2 dangerouslySetInnerHTML={{ __html: subtitleClean }} />
    </div>
  )
}

export default BlockSubtitle
