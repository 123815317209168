import { gql } from '@apollo/client'

const META_QUERY = gql`
  query MetaQuery($siteId: [QueryArgument]) {
    global: globalSet(handle: "globalConfig", siteId: $siteId) {
      id
      siteId
      ... on globalConfig_GlobalSet {
        defaultMetaTitle
        defaultMetaDescription
        defaultMetaImage {
          url
        }
        googleAnalyticsId
        membershipFormSuccessMsg
      }
    }
  }
`

export default META_QUERY
