import React, { ReactElement } from 'react'
import classnames from 'classnames'
import striptags from 'striptags'

import { ImageType } from 'data/types'
import Image from 'view/components/image/Image'

import './BlockImage.scss'

type Props = {
  blockStyle: string
  blockImage: ImageType[]
  blockCaption: string
}

const BlockImage = ({
  blockStyle,
  blockImage,
  blockCaption,
}: Props): ReactElement | null => {
  const blockImageOne = blockImage.length > 0 && blockImage[0]

  if (!blockImageOne) {
    return null
  }

  // 1. image
  const image = blockImageOne && (
    <figure className="BlockImage__figure">
      <div
        className="BlockImage__ratio"
        style={{
          paddingTop: `${(blockImageOne.height / blockImageOne.width) * 100}%`,
        }}
      />
      <Image image={blockImageOne} className="BlockImage__img" />
    </figure>
  )

  // 2. caption
  const captionClean = striptags(blockCaption, [
    'p',
    'br',
    'em',
    'i',
    'a',
    'ul',
    'li',
    'sup',
    'sub',
  ])
  const caption = captionClean && (
    <div
      className="BlockImage__caption"
      dangerouslySetInnerHTML={{ __html: captionClean }}
    />
  )

  return (
    <div
      className={classnames('BlockImage', `BlockImage--size-${blockStyle}`, {
        'BlockImage--portrait': blockImageOne.height / blockImageOne.width > 1,
      })}
    >
      {image}
      {caption}
    </div>
  )
}

export default BlockImage
