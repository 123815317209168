import React, { ReactElement } from 'react'
import classnames from 'classnames'
import striptags from 'striptags'

import { AgendaItemStyledType } from 'data/types'

import './AgendaItem.scss'

const AgendaItem = ({
  id,
  isDark,
  headline,
  subtitle,
  lead,
  agendaDate,
  agendaTimeStart,
  agendaTimeEnd,
  agendaLocation,
}: AgendaItemStyledType): ReactElement => {
  const headlineClean: string = striptags(headline, [
    'br',
    'em',
    'i',
    'a',
    'sup',
    'sub',
  ])
  const subtitleClean: string = striptags(subtitle, [
    'br',
    'em',
    'i',
    'a',
    'sup',
    'sub',
  ])
  const leadClean: string = striptags(lead, [
    'br',
    'em',
    'i',
    'a',
    'p',
    'sup',
    'sub',
  ])
  let time = null
  const startTimeWithoutZeroHrs =
    agendaTimeStart && agendaTimeStart.replace('.00', '')
  const endTimeWithoutZeroHrs =
    agendaTimeEnd && agendaTimeEnd.replace('.00', '')

  if (agendaTimeEnd && agendaTimeEnd !== agendaTimeStart) {
    time = (
      <span>
        {startTimeWithoutZeroHrs}–{endTimeWithoutZeroHrs} Uhr
      </span>
    )
  } else {
    time = <span>{startTimeWithoutZeroHrs} Uhr</span>
  }

  return (
    <div
      key={id}
      className={classnames('AgendaItem', { 'AgendaItem--dark': isDark })}
    >
      <div className="AgendaItem__inner">
        <div className="AgendaItem__center">
          {headlineClean && (
            <div className="AgendaItem__headline">
              <h1 dangerouslySetInnerHTML={{ __html: headlineClean }} />
            </div>
          )}
          {subtitleClean && (
            <div className="AgendaItem__subtitle">
              <h2 dangerouslySetInnerHTML={{ __html: subtitleClean }} />
            </div>
          )}
          {leadClean && (
            <div
              className="AgendaItem__lead"
              dangerouslySetInnerHTML={{ __html: leadClean }}
            />
          )}
        </div>

        <div className="AgendaItem__left">
          <span className="AgendaItem__date">{agendaDate}</span>
          <span className="AgendaItem__time">{time}</span>
          <span className="AgendaItem__location">{agendaLocation}</span>
        </div>
      </div>
    </div>
  )
}

export default AgendaItem
