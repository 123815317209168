import { MessagesType, SupportedSitesType } from 'data/types'
import messagesDE from 'translations/de.json'
import messagesEN from 'translations/en.json'

export const defaultLang = 'de'
export const defaultSite = '1'

export const supportedLangs = {
  de: 'Deutsch',
  // en: 'English',
}

export const supportedSites: SupportedSitesType = {
  de: '1',
  // en: '2',
}

export const messages: MessagesType = {
  de: messagesDE,
  en: messagesEN,
}

const stripCountry = (lang: string) =>
  lang.trim().replace('_', '-').split('-')[0]

export const findFirstSupported = (langs: string[]): string | undefined => {
  const supported = Object.keys(supportedLangs)
  return langs.find((code) => supported.includes(code))
}

export const determineUserLang = (
  acceptedLangs: readonly string[],
  path: null | string = null
): string => {
  // check for path based lang
  if (path !== null) {
    const urlLang = path.trim().split('/')[1]
    const matchingUrlLang = findFirstSupported([stripCountry(urlLang)])

    if (matchingUrlLang) {
      return matchingUrlLang
    }
  }

  // check for browser-set accepted langs
  const matchingAcceptedLang = findFirstSupported(
    acceptedLangs.map(stripCountry)
  )

  return matchingAcceptedLang || defaultLang
}

export const mapLangToSiteId = (lang: string): string => {
  const supported = Object.keys(supportedSites)

  if (supported.includes(lang)) {
    return supportedSites[lang]
  }

  return defaultSite
}
