import { useQuery } from '@apollo/client'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { mapLangToSiteId } from 'misc/i18n'
import { RootState } from 'data/redux/rootReducer'
import {
  globalChangeFilters,
  globalCloseArticles,
} from 'data/redux/global/actions'
import HEADER_QUERY from 'data/queries/header'
import Header from './Header'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
  filters: state.global.filters,
  searchValue: state.global.search,
})

const mapDispatchToProps = {
  actionChangeFilters: globalChangeFilters,
  actionCloseArticles: globalCloseArticles,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type HeaderProviderProps = ConnectedProps<typeof connector>

const HeaderProvider = ({
  actionChangeFilters,
  actionCloseArticles,
  filters,
  locale,
  searchValue,
}: HeaderProviderProps) => {
  const { data } = useQuery(HEADER_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
    },
  })

  if (data) {
    return (
      <Header
        actionChangeFilters={actionChangeFilters}
        actionCloseArticles={actionCloseArticles}
        filters={filters}
        locale={locale}
        searchValue={searchValue}
        {...data}
      />
    )
  }

  return null
}

export default connector(HeaderProvider)
