import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { ReactElement, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { sortBy } from 'lodash'

import { BASE_URL } from 'Constants'
import { mapLangToSiteId } from 'misc/i18n'
import { RootState } from 'data/redux/rootReducer'
import AGENDA_QUERY from 'data/queries/agenda'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Loading from 'view/components/loading/Loading'
import Agenda from './Agenda'

const mapStateToProps = (state: RootState) => ({
  locale: state.global.locale,
  searchValue: state.global.search,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const AgendaProvider = ({
  locale,
  searchValue,
}: PropsFromRedux): ReactElement | null => {
  const today = moment().format('YYYY-MM-DD')

  const { data, loading } = useQuery(AGENDA_QUERY, {
    variables: {
      siteId: mapLangToSiteId(locale),
      startDate: [`>= ${today}`],
      search: searchValue,
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const helmetParams = {
    title: 'Agenda',
    description: 'Agenda',
    url: `${BASE_URL}/${locale}/agenda`,
  }

  if (loading) {
    return (
      <>
        <Helmet {...helmetParams} />
        <Loading />
      </>
    )
  }

  if (data) {
    // @NOTE: because there is a bug in the Craft time field,
    // which sets a date in addition to the time, the field
    // cannot be ordered by via GraphQL, and therefore we have
    // to do it here on the frontend.
    const results = sortBy(data.results, (item) =>
      moment(
        `${item.agendaDate} ${item.agendaTimeStart}`,
        'DD.MM.YYYY hh:mm'
      ).toDate()
    )

    return (
      <>
        <Helmet {...helmetParams} />
        <Agenda results={results} />
      </>
    )
  }

  return null
}

export default connector(AgendaProvider)
