import React, { ReactElement } from 'react'
import striptags from 'striptags'

import { PeopleGalleryType, PersonType } from 'data/types'
import Image from 'view/components/image/Image'

import './BlockPersonGallery.scss'

type Props = {
  blockTitle: string
  blockGallery: PeopleGalleryType[]
}

const BlockPersonGallery = ({
  blockTitle,
  blockGallery,
}: Props): ReactElement => {
  // 1. title
  const titleClean = striptags(blockTitle, ['br', 'em', 'i', 'sup', 'sub'])

  // 2. related people gallery
  let items = null
  const relatedPeopleGallery = blockGallery.length > 0 && blockGallery[0]

  if (relatedPeopleGallery) {
    // render items (slides)
    items = relatedPeopleGallery.people.map((item: PersonType) => {
      const { id, blockHidden, blockImage, blockCaption } = item
      const blockImageOne = blockImage.length > 0 && blockImage[0]

      if (blockHidden || !blockImageOne) {
        return null
      }

      const captionClean = striptags(blockCaption, [
        'br',
        'em',
        'i',
        'a',
        'sup',
        'sub',
      ])
      const caption = captionClean && (
        <div
          className="BlockPersonGallery__grid__item__caption"
          dangerouslySetInnerHTML={{ __html: captionClean }}
        />
      )

      return (
        <div className="BlockPersonGallery__grid__item" key={id}>
          <figure className="BlockPersonGallery__grid__item__figure">
            <div
              className="BlockPersonGallery__grid__item__ratio"
              style={{ paddingTop: '125%' }}
            />
            <Image
              image={blockImageOne}
              className="BlockPersonGallery__grid__item__img"
            />
          </figure>
          {caption}
        </div>
      )
    })
  }

  return (
    <div className="BlockPersonGallery">
      <div className="BlockPersonGallery__title">
        <h2 dangerouslySetInnerHTML={{ __html: titleClean }} />
      </div>
      {items && <div className="BlockPersonGallery__grid">{items}</div>}
    </div>
  )
}

export default BlockPersonGallery
