import React, { useState, useEffect, useRef, ReactElement } from 'react'
import Clipboard from 'react-clipboard.js'
import { useIntl } from 'react-intl'
import { CSSTransition } from 'react-transition-group'
import encodeurl from 'encodeurl'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { ShareProviderProps } from './ShareProvider'

import './Share.scss'

const Share = ({
  open,
  content,
  imageFallback,
  actionShareCloseDialog,
}: ShareProviderProps): ReactElement => {
  const [copyDone, setCopyDone] = useState(false)
  const [timer, setTimer] = useState<any>(null)
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const { headline, lead, image, url } = content

  const actionShareCloseDialogClick = () => {
    if (ref.current) {
      enableBodyScroll(ref.current)
    }

    setCopyDone(false)
    actionShareCloseDialog()
  }

  const onCopySuccess = () => {
    setCopyDone(true)
    setTimer(window.setTimeout(() => setCopyDone(false), 2000))
  }

  const handleFacebookClick = () => {
    const popupWidth = 626
    const popupHeight = 436

    // center popup
    // Fixes dual-screen position Most browsers Firefox
    const dualScreenLeft =
      window.screenLeft !== undefined
        ? window.screenLeft
        : (window as any).screen.left
    const dualScreenTop =
      window.screenTop !== undefined
        ? window.screenTop
        : (window as any).screen.top
    const winWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width
    const winHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

    const popupTop = winHeight / 2 - popupHeight / 2 + dualScreenTop
    const popupLeft = winWidth / 2 - popupWidth / 2 + dualScreenLeft

    const newWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?display=popup&href=${url}&fallback_redirect_uri=${url}`,
      'facebook-share-dialog',
      `toolbar=0, status=0, resizable=1, width=${popupWidth}, height=${popupHeight}, top=${popupTop}, left=${popupLeft}`
    )

    // Puts focus on the newWindow
    if ((window as any).focus) {
      ;(newWindow as any).focus()
    }
  }

  // fix potential memory leak
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if ((event.key === 'Esc' || event.key === 'Escape') && open) {
        if (ref.current) {
          enableBodyScroll(ref.current)
        }

        setCopyDone(false)
        actionShareCloseDialog()
      }
    }

    if (open && ref.current) {
      disableBodyScroll(ref.current)
    }

    window.addEventListener('keydown', keyDownHandler)

    return () => {
      window.clearTimeout(timer)
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [timer, open])

  const textShareWhatsApp = formatMessage({
    id: 'ShareWhatsApp',
    defaultMessage: 'Per WhatsApp teilen',
  })

  const textShareFacebook = formatMessage({
    id: 'ShareFacebook',
    defaultMessage: 'Per Facebook teilen',
  })

  const textShareMail = formatMessage({
    id: 'ShareMail',
    defaultMessage: 'Per Mail teilen',
  })

  const textShareCopyLink = formatMessage({
    id: 'ShareCopyLink',
    defaultMessage: 'Link kopieren',
  })

  const textShareCopyLinkDone = formatMessage({
    id: 'ShareCopyLinkDone',
    defaultMessage: 'Kopiert',
  })

  const textShareClose = formatMessage({
    id: 'ShareClose',
    defaultMessage: 'Schliessen',
  })

  const textUrlEncoded = encodeurl(
    `${headline}${lead ? ', ' + lead : ''}, ${url}`
  )

  return (
    <div className="Share">
      {open && (
        <button
          className="Share__outerclose"
          onClick={actionShareCloseDialogClick}
        />
      )}

      <CSSTransition
        in={open}
        timeout={300}
        classNames="h-transition"
        unmountOnExit
      >
        <div className="Share__container" ref={ref}>
          <div className="Share__inner">
            <div className="Share__text">
              <div className="Share__headline">
                <h1 dangerouslySetInnerHTML={{ __html: headline }} />
              </div>
              <div
                className="Share__lead"
                dangerouslySetInnerHTML={{ __html: lead }}
              />
            </div>

            {image && (
              <div className="Share__img">
                <img src={image} alt={headline} />
              </div>
            )}

            {!image && imageFallback && (
              <div className="Share__fallbackimg">
                <img src={imageFallback} alt={headline} />
              </div>
            )}

            <div className="Share__link">
              <a
                className="Share__link__anchor"
                href={`https://wa.me/?text=${textUrlEncoded}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="Share__link__svg">
                  <svg viewBox="0 0 20 20">
                    <path
                      d="M13.69,11.35c-0.19-0.13-1.2-0.57-1.33-0.63c-0.19-0.06-0.32-0.13-0.44,0.13c-0.13,0.19-0.5,0.63-0.63,0.76
                      c-0.13,0.13-0.25,0.13-0.44,0.06c-0.19-0.13-0.82-0.32-1.58-0.95c-0.57-0.5-1.01-1.14-1.07-1.33c-0.13-0.19,0-0.32,0.06-0.38
                      c0.06-0.06,0.42-0.47,0.48-0.6c0.06-0.13,0.1-0.18,0.03-0.35C8.7,7.9,8.33,6.99,8.14,6.61C7.95,6.24,7.82,6.3,7.7,6.3
                      c-0.13,0-0.25,0-0.38,0C7.13,6.11,6.94,6.17,6.75,6.36S6.06,6.99,6.06,8c0,0.25,0.06,0.44,0.13,0.69c0.19,0.69,0.63,1.26,0.69,1.39
                      c0.13,0.13,1.39,2.15,3.34,2.97c1.76,0.66,1.99,0.62,2.41,0.52c0.42-0.1,1.19-0.46,1.31-0.96c0.19-0.44,0.19-0.82,0.13-0.95
                      C14.01,11.54,13.88,11.47,13.69,11.35z"
                    />
                    <g>
                      <path
                        d="M9.94,2.22c4.17,0,7.56,3.39,7.56,7.56c0,4.17-3.39,7.56-7.56,7.56c-1.31,0-2.61-0.34-3.74-0.99L6,16.24L5.8,16.3
                        L2.4,17.38l1.09-3.32l0.07-0.22l-0.12-0.2c-0.7-1.17-1.07-2.51-1.07-3.88C2.38,5.61,5.77,2.22,9.94,2.22 M9.94,1.72
                        c-4.45,0-8.06,3.61-8.06,8.06c0,1.51,0.42,2.92,1.14,4.13l-1.39,4.25l4.33-1.38c1.18,0.67,2.54,1.06,3.99,1.06
                        c4.45,0,8.06-3.61,8.06-8.06C18,5.33,14.39,1.72,9.94,1.72L9.94,1.72z"
                      />
                    </g>
                  </svg>
                </div>
                <div className="Share__link__text">{textShareWhatsApp}</div>
              </a>
            </div>

            <button className="Share__link__fb" onClick={handleFacebookClick}>
              <div className="Share__link__fb__inner">
                <div className="Share__link__svg">
                  <svg viewBox="0 0 20 20">
                    <path d="M11.5,17.6v-7.2h2.2L14,7.2h-2.5V5.7c0-0.8,0-1.6,1.2-1.6h1.2V1.7c0,0-1-0.1-2-0.1C9.7,1.6,8.4,3,8.4,5.4v1.8H6v3.2h2.3v7.2L11.5,17.6L11.5,17.6z" />
                  </svg>
                </div>
                <div className="Share__link__text">{textShareFacebook}</div>
              </div>
            </button>

            <div className="Share__link">
              <a
                className="Share__link__anchor"
                href={`mailto:?subject=Kunstverein St.Gallen – ${headline}&body=${textUrlEncoded}`}
              >
                <div className="Share__link__svg">
                  <svg viewBox="0 0 20 20">
                    <path d="M16.58,5.41v8.54H3.08V5.41H16.58 M17.08,4.91H2.58v9.54h14.5V4.91L17.08,4.91z" />
                    <polyline
                      className="Share__link__svg__mail"
                      points="2.9,5.12 9.83,12.02 16.73,5.13"
                    />
                    <line
                      className="Share__link__svg__mail"
                      x1="7.46"
                      y1="9.66"
                      x2="2.9"
                      y2="14.21"
                    />
                    <line
                      className="Share__link__svg__mail"
                      x1="16.73"
                      y1="14.23"
                      x2="12.17"
                      y2="9.68"
                    />
                  </svg>
                </div>
                <div className="Share__link__text">{textShareMail}</div>
              </a>
            </div>

            <Clipboard
              data-clipboard-text={url}
              onSuccess={onCopySuccess}
              className="Share__link__copy"
            >
              <div className="Share__link__copy__inner">
                <div className="Share__link__svg">
                  <svg viewBox="0 0 96.32 93.18">
                    <path
                      d="M31.77,61.88c0.78,0.78,2.05,0.78,2.83,0l26.06-26.06c0.78-0.78,0.78-2.05,0-2.83s-2.05-0.78-2.83,0L31.77,59.05
                      C30.99,59.83,30.99,61.1,31.77,61.88z"
                    />
                    <path
                      d="M54,17.77l-4.92,4.92c-4.53,4.53-5.66,11.19-3.39,16.78l3.18-3.18c-0.89-3.75,0.11-7.85,3.03-10.77l4.92-4.92
                      c4.47-4.47,11.75-4.47,16.22,0s4.47,11.75,0,16.22l-4.92,4.92c-2.92,2.92-7.02,3.92-10.77,3.03l-3.18,3.18
                      c5.59,2.27,12.25,1.14,16.78-3.39l4.92-4.92c6.03-6.03,6.03-15.85,0-21.88C69.85,11.74,60.03,11.74,54,17.77z"
                    />
                    <path
                      d="M40.51,69.36l-4.92,4.92c-4.47,4.47-11.75,4.47-16.22,0s-4.47-11.75,0-16.22l4.92-4.92c2.92-2.92,7.02-3.92,10.77-3.03
                      l3.18-3.18c-5.59-2.27-12.25-1.14-16.78,3.39l-4.92,4.92c-6.03,6.03-6.03,15.85,0,21.88c6.03,6.03,15.85,6.03,21.88,0l4.92-4.92
                      c4.53-4.53,5.66-11.19,3.39-16.78l-3.18,3.18C44.43,62.34,43.43,66.45,40.51,69.36z"
                    />
                  </svg>
                </div>
                <div className="Share__link__text">
                  {copyDone ? textShareCopyLinkDone : textShareCopyLink}
                </div>
              </div>
            </Clipboard>

            <button
              className="Share__close"
              onClick={actionShareCloseDialogClick}
            >
              {textShareClose}
            </button>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default Share
