import React from 'react'

import { PersonType } from 'data/types'
import BlockPersonGallery from 'view/components/blocks/BlockPersonGallery'

type Props = {
  id: string
  title: string
  people: PersonType[]
}

const PersonGallery = ({ id, title, people }: Props) => {
  const blockGallery = [
    {
      id: id,
      people,
    },
  ]

  return (
    <section className="Page">
      <div className="Page__inner">
        <BlockPersonGallery blockTitle={title} blockGallery={blockGallery} />
      </div>
    </section>
  )
}

export default PersonGallery
