import { useState, useEffect } from 'react'

import { IS_SERVER_SIDE } from 'Constants'

export const SSR_WINDOW_WIDTH = 1200
export const SSR_WINDOW_HEIGHT = 1200

type useWindowSizeReturn = {
  winWidth: number
  winHeight: number
}

// Hook
function useWindowSize(onlyWidthChanges = false): useWindowSizeReturn {
  function getSize() {
    return {
      winWidth: IS_SERVER_SIDE ? SSR_WINDOW_WIDTH : window.innerWidth,
      winHeight: IS_SERVER_SIDE ? SSR_WINDOW_HEIGHT : window.innerHeight,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (IS_SERVER_SIDE) {
      return
    }

    function handleResize() {
      if (onlyWidthChanges) {
        if (windowSize.winWidth !== getSize().winWidth) {
          setWindowSize(getSize())
        }
      } else {
        setWindowSize(getSize())
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return windowSize
}

export default useWindowSize
